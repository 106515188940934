import React, { useState } from 'react';
import { TextInput } from '../../../../../components';
import IconArrowUp from '../../../../../images/icon-arrow-up-min.svg'
import IconArrowDown from '../../../../../images/icon-arrow-down-min.svg'
import IconArrowRight from '../../../../../images/icon-arrow-right-min.svg'
import styles from './PickerClock.module.css'

export function PickerClock({
 value,
 onChange,
 error,
 showTextError,
 label
}) {
  const [showModal, setShowModal] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const onChangeHour = (text) => {
    if (text >= 23) {
      setHours(23)
    } else {
      setHours(text)
    }
  }

  const plusHour = () => {
    if (!hours) {
      setHours(1)
    }

    if (hours < 23) {
      setHours(hours + 1)
    } else {
      setHours(0)
    }
  }

  const minusHour = () => {
    if (hours) {
      setHours(hours - 1)
    } else {
      setHours(23)
    }
  }

  const onChangeMinutes = (text) => {
    if (text >= 59) {
      setMinutes(59)
    } else {
      setMinutes(text)
    }
  }

  const plusMinute = () => {
    if (!minutes) {
      setMinutes(1)
    }

    if (minutes < 59) {
      setMinutes(minutes + 1)
    } else {
      setMinutes(0)
    }
  }

  const minusMinute = () => {
    if (minutes) {
      setMinutes(minutes - 1)
    } else {
      setMinutes(59)
    }
  }

  const save = () => {
    let stringHour = ''
    let stringMinute = ''

    if (10 > hours) {
      stringHour = '0' + hours
    } else {
      stringHour = hours
    }

    if (10 > minutes) {
      stringMinute = '0' + minutes
    } else {
      stringMinute = minutes
    }

    onChange(stringHour + ':' + stringMinute)
    setShowModal(false)
  }

  return (
    <div className={styles.root}>
      <TextInput
        type='custom'
        label={label}
        value={value}
        error={error}
        showTextError={showTextError}
        onFocus={() => setShowModal(true)}
        placeholder='Час'
      />
      {showModal && (
        <div className={styles.blockTime}>
          <div className={styles.blockUp}>
            <div className={styles.blockClock}>
              <button className={styles.btn} onClick={plusHour}>
                <img src={IconArrowUp} />
              </button>
              <input
                value={hours}
                onChange={(e) => onChangeHour(+e.target.value)}
                className={styles.inputClock}
                type='number'
                min={0}
                max={24}
              />
              <button className={styles.btn} onClick={minusHour}>
                <img src={IconArrowDown} />
              </button>
            </div>
            <p className={styles.textDots}> : </p>
            <div className={styles.blockClock}>
              <button className={styles.btn} onClick={plusMinute}>
                <img src={IconArrowUp} />
              </button>
              <input
                value={minutes}
                onChange={(e) => onChangeMinutes(+e.target.value)}
                className={styles.inputClock}
                type='number'
                min={0}
                max={59}
              />
              <button className={styles.btn} onClick={minusMinute}>
                <img src={IconArrowDown} />
              </button>
            </div>
          </div>
          <div className={styles.blockDown} onClick={save}>
            <p className={styles.textSave}>Обрати час</p>
            <img src={IconArrowRight} />
          </div>
        </div>
      )}
    </div>
  );
}
