import {
  REMOVE_TOAST,
  PUSH_NEW_TOAST
} from '../constants';

const initialState = []

const toasts = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_NEW_TOAST:
      let newState1 = [...state];
      newState1.push(action.payload);

      return newState1;
    case REMOVE_TOAST:
      let newState2 = [...state];
      newState2.splice(action.payload.index, 1);

      return newState2;
    default:
      return state;
  }
}
export default toasts
