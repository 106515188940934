import React, {useState, useRef} from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

import {login} from '../../../models'
import {requestCreateUser, requestUploadAvatar, requestGetMyAvatar} from '../../../constants/request'
import {TextInput, DataPickerInput} from '../../../components'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import AvatarDefault from '../../../images/avatar.png'
import {
  useHistory
} from "react-router-dom"
import { loadingTrue, loadingFalse } from '../../../actions/loading'
import {confirmCancel, confirmOk} from '../../../actions/confirm'

import {requestRestPW, requestCompletePW} from '../../../constants/request'

import {
  SUB_TAB_RES_0,
  SUB_TAB_RES_1,
  SUB_TAB_RES_2
} from '../../../constants/index'

import {
  setTabLogin,
  setSubTabRes1,
  setSubTabRes2
} from '../../../actions/setTab'

// import './picker.module.css'

export default function ResetPW ({token = null}) {
  const refUpload = useRef()
  const {authTab} = useSelector(state => state)
  const resStep = authTab.resStep
  const dispatch = useDispatch()

  const [errEmail, setErrEmail] = useState('')
  const [email, setEmail] = useState('')
  const history = useHistory()

  const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  // console.log(window.location.href, 'window.location.href')

  const sendEmail = async () => {
    if (!email) return setErrEmail('Введіть пошту')
    if (!regEmail.test(email)) return setErrEmail('Пошта неправильна')

    try {
      await axios.post(requestRestPW + `?email=${email}&link=${window.location.href}`)
    } catch (e) {
      console.log(e)
      console.log('error rest password')
    }

    dispatch(setSubTabRes1())
  }

  const changePassword = async (data) => {
    try {
      await axios.post(requestCompletePW + `?token=${token}&email=${data.email}&newPwd=${data.password}`)
      history.push('/')
      dispatch(setTabLogin())
    } catch (e) {
      console.log(e)
      console.log('error complete password')
      dispatch(confirmOk({
        title: 'Помилка при відновленні паролю',
        open: true,
        errorAlert: true,
        onClose: () => {
          dispatch(confirmCancel())
        },
      }))
    }
  }


  const zeroStep = (
    <div className='formInput'>
      <p className='auth label white'>ЗАБУВ ПАРОЛЬ?</p>
      <TextInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={errEmail}
        showTextError={true}
        placeholder='Пошта'
      />
      <br />
      <div className='block-buttons'>
        <button className='button bg-gray' onClick={()=>dispatch(setTabLogin())}>
          <p className='title t-gray'>ЗАКРИТИ</p>
        </button>
        <button className='button bg-white' onClick={sendEmail}>
          <p className='title'>ВІДНОВИТИ</p>
        </button>
      </div>
    </div>
  )

  const firstStep = (
        <div className='formInput' style={{
          marginTop: 100,
          marginBottom: 100,
          width: 250
        }}>
          <p className='title t-white'>ПЕРЕВІР ПОШТУ</p>
          <p className='t-white'>Ми надiслали тобi листа iз посиланням на створення нового паролю. Не зволікай!</p>
          <br/>
          <div style={{
            display: 'flex',
            justyfiContent: 'center'
          }}>
            <button className='button bg-white' onClick={()=>dispatch(setTabLogin())}>
              <p className='title'>ПОВЕРНУТИСЯ</p>
            </button>
          </div>
        </div>
  )

  const initDataThreeStep = {
    email: '',
    password: '',
    confirmPassword: ''
  }

  const validationThree = Yup.object({
    email: Yup.string()
      .email('Пошта невірна')
      .required('Введіть пошту'),
    password: Yup.string()
      .min(9, 'Повинен бути не менше 9 символів')
      .max(20, 'Повинен бути не більше 20 символів')
      .required('Введіть новий пароль'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароль повинен збігатися')
      .required('Підтвердіть новий ароль')
  })


  const secondStep = (
    <Formik
      initialValues={initDataThreeStep}
      onSubmit={(values, { setSubmitting }) => {
        changePassword(values)
        setSubmitting(false)
      }}
      validationSchema={validationThree}
    >
      {({
          setFieldValue,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
        }) => (
        <div className='formInput'>
          <p className='auth label white'>ЗМІНА ПАРОЛЮ</p>
          <TextInput
            value={values.email}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            error={errors.email}
            showTextError={true}
            placeholder='Пошта'
          />
          <br />
          <TextInput
            value={values.password}
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            error={errors.password}
            showTextError={true}
            type='password'
            placeholder='Новий пароль'
          />
          <br />
          <TextInput
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            onBlur={handleBlur('confirmPassword')}
            error={errors.confirmPassword}
            showTextError={true}
            type='password'
            placeholder='Повторіть новий пароль'
          />
          <br />
          <div style={{
            display: 'flex',
            justyfiContent: 'center'
          }}>
            <button disabled={isSubmitting} className='button bg-white' onClick={handleSubmit}>
              <p className='title'>ЗБЕРЕГТИ</p>
            </button>
          </div>
        </div>
      )}
    </Formik>
  )

  if (token) {
    return secondStep
  }

  switch (resStep) {
    case SUB_TAB_RES_0:
      return zeroStep
    case SUB_TAB_RES_1:
      return firstStep
    case SUB_TAB_RES_2:
      return secondStep
    default:
      return zeroStep
  }
}
