import { CONFIRM_OK, CONFIRM_CANCEL } from '../constants/index'

const initialState = {
  title: '',
  open: false,
  errorAlert: false,
  onClose: () => {},
  onOk: () => {}
}

const confirm = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_OK:
      return action.payload
    case CONFIRM_CANCEL:
      return initialState
    default:
      return initialState
  }
}

export default confirm
