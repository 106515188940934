// @ts-nocheck
/* eslint-disable */

import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
} from 'axios';
import { store } from '../App';
import {pushToast} from '../actions/toasts';
import apiRes from './api-responses.json';
import { logout } from '../actions/login';

abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  protected accessToken = localStorage.getItem('token') || '';

  public constructor(baseUrl: string) {
    this.instance = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ${this.accessToken}`
      },
    });

    this.instance.interceptors.request.use(this._handleRequest, this._handleError);
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  }

  private _handleRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    Object.assign(config.headers, {
      Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
    });
    const tokenVal = localStorage.getItem('tokenStartTime') || '';

    if (tokenVal) {
      const oldTime = new Date(tokenVal);
      const newTime = new Date();

      oldTime.setDate(oldTime.getDate() + 14);

      if (oldTime.getTime() < newTime.getTime()) {
        localStorage.setItem('token', '');
        localStorage.setItem('tokenStartTime', '');
        store.dispatch(logout())
      }
    }

    if (
      config.url &&
      config.url.indexOf('refresh') === -1 &&
      config.url.indexOf('login') === -1
    ) {
      // const tokenVal = localStorage.getItem('tokenStartTime') || '';
      // const time = new Date(tokenVal);
      // if (
      //   tokenVal !== '' &&
      //   new Date() > new Date(time.setMinutes(time.getMinutes() + 50))
      // ) {
      //   this.refreshAccessToken().then((response) => {
      //     localStorage.setItem('token', response.data.data.attributes.access_token);
      //     localStorage.setItem('tokenStartTime', new Date().toUTCString());
      //   });
      // }
    }
    return config;
  };

  private _handleResponse = (
    response: AxiosResponse
  ): AxiosResponse | Promise<AxiosResponse> => {
    return response;
  };

  private _handleError = (error: AxiosError) => {
    if (error.response) {
      let isShow = true;
      if (store.getState().toasts?.length > 0) {
        const texts = store.getState().toasts.map(el => el?.text) || []
        const index = texts.indexOf(apiRes[error.response.status.toString()][error.response.data.messageLabel])

        if (index !== -1) {
          isShow = false;
        }
      }

      if (isShow) {
        store.dispatch(pushToast({
          status: 'error',
          text: apiRes[error.response.status.toString()][error.response.data.messageLabel],
        }))
      }
    }

    return Promise.reject(error.response);
  }

  private refreshAccessToken = async () => {
    return await this.instance.post<any>('auth/refresh', {}, {});
  };
}

export default HttpClient;
