import axios from 'axios'
import {requestLogin} from '../constants/request'
import {
  fetchUserReuest,
  fetchUserSuccess,
  fetchUserFailure
} from '../actions/login';
import MainApi from '../utils/mainApi';

const login = (data, setError = () => {}) => {
  const mainApi = MainApi.getInstance();
  return async (dispatch) => {
    try {
      dispatch(fetchUserReuest())
      let res = await mainApi.login(data);
      const user = res.data
      await localStorage.setItem('token', user.token)
      await localStorage.setItem('tokenStartTime', new Date().toUTCString());
      dispatch(fetchUserSuccess(user))
      return user.token
    } catch (e) {
      setError('Невірний логін або пароль')
      dispatch(fetchUserFailure(e))
      return null
    }
  }
}

export default login
