import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_CLEAR
} from '../constants/index'

const initialState = {
  loading: false
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_USERS_SUCCESS:
      return {
        loading: false,
        isError: '',
        ...state,
        ...action.payload
      }
    case FETCH_USERS_FAILURE:
      return {
        loading: false,
        isError: action.payload,
        ...state,
        ...action.payload,
      }
    case FETCH_USERS_CLEAR:
      return {
        loading: false
      }
    default:
      return {
        ...state,
        ...action.payload
      }
  }
}
export default user
