// export const BASE_URL = 'https://volonteryou-backend.herokuapp.com'
export const BASE_URL = 'https://app.volonteryou.com'
// export const BASE_URL = 'http://localhost:8080'

// todo: types users
// ORGANIZATION
// VOLUNTEER
// ADMIN


export const requestRestPW = BASE_URL + '/users/pwd/restore'
export const requestCompletePW = BASE_URL + '/users/pwd/restore/complete'

export const requestLogin = BASE_URL + '/login'
export const requestCreateUser = BASE_URL + '/users'
export const requestCheckExistUser = BASE_URL + '/users'
export const requestUpdateMyUser = BASE_URL + '/api/0.1/users'
export const requestGetMyUser = BASE_URL + '/api/0.1/users'
export const requestUploadAvatar = BASE_URL + '/api/0.1/users/picture'
export const requestGetMyAvatar = BASE_URL + '/api/0.1/users/picture?originalSize=true'

// todo: add id
export const requestGetUserById = BASE_URL + '/api/0.1/users'

// todo: mission
export const requestCreateTask = BASE_URL + '/api/0.1/missions'
export const requestGetTask = BASE_URL + '/api/0.1/missions/upcoming'
export const requestGetTaskOrg = BASE_URL + '/api/0.1/missions/'
export const requestDeleteTask = BASE_URL + '/api/0.1/missions'
export const requestGetTaskVolunteers = BASE_URL + '/api/0.1/missions'
export const requestFinishTask = BASE_URL + '/api/0.1/missions'

export const joinToTask = BASE_URL + '/api/0.1/user-missions'
export const getVolonterTask = BASE_URL + '/api/0.1/user-missions/all'

export const requestTest = BASE_URL + '/health'

export const requestFooterFeedback = BASE_URL + '/users/feedback'
