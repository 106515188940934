import React from 'react';
import IconArrow from '../../../images/arrow.png';
import IconIsleOne from '../../../images/Isle1.svg';
import IconIsleTwo from '../../../images/Isle2.svg';
import IconIsleThree from '../../../images/Isle3.svg';

import IconIsleWithNumberOne from '../../../images/isleWithNumberOne.svg';
import IconIsleWithNumberTwo from '../../../images/isleWithNumberTwo.svg';
import IconIsleWithNumberThree from '../../../images/isleWithNumberThree.svg';
import IconIsleWithNumberFour from '../../../images/isleWithNumberFour.svg';
import IconIsleWithNumberFive from '../../../images/isleWithNumberFive.svg';

import IconGreenArrow from '../../../images/greenArrow.svg';
import IconHeart from '../../../images/heart.svg';
import IconHand from '../../../images/hand.svg';
import IconSmiley from '../../../images/smiley.svg';
import IconPuzzle from '../../../images/puzzle.svg';

import IconSlug from '../../../images/slug.png';
import IconParty from '../../../images/party.svg';
import IconView from '../../../images/view.svg';
import IconCommunity from '../../../images/community.svg';
import IconFine from '../../../images/icon-fine.svg';
import IconApply from '../../../images/icon-apply.svg';
import IconMeet from '../../../images/icon-meet.svg';
import IconChange from '../../../images/icon-change.svg';

import {Footer} from '../../../components'

import './index.css';
// import style from './Lending.module.css';

import Fade from 'react-reveal/Fade';

const Lending = ({ setTab = () => {} }) => {
  return (
    <div className='lending-container'>
      <div className='lending-greeting' >
        <div className='lending-greeting__tiltles'>
          <Fade bottom>
            <h6 className='lending-greeting__title lending-greeting__title--top'>
              ЛАСКАВО ПРОСИМО
            </h6>
          </Fade>

          <img className='lending-greeting__arrow' src={IconArrow} />

          <Fade bottom>
            <h6 className='lending-greeting__title lending-greeting__title--middle'>
              У СВІТ
            </h6>
          </Fade>

          <Fade bottom>
            <h6 className='lending-greeting__title lending-greeting__title--bottom'>
              ВОЛОНТЕРСТВА
            </h6>
          </Fade>
        </div>


        <button className='lending-greeting__button-begin' onClick={setTab} style={{
          cursor: 'pointer'
        }}>РОЗПОЧАТИ!</button>
      </div>

      <div className='lending-rules'>
        <h6 className='lending-rules__title'>
          ПРАВИЛА ГРИ
        </h6>

        <div className='lending-rules__section'>
          <div className='lending-rules__item lending-rules__item--first'>
           <img className='lending-rules__item-img' src={IconIsleWithNumberOne} />

            <div className='lending-rules__item-wrap'>
              <h4 className='lending-rules__item-title'>РЕЄСТРУЙСЯ</h4>
              <p className='lending-rules__item-description'>Pозкажи про себе i потрапляй у свiт волонтерства</p>
            </div>
          </div>

          <div className='lending-rules__item lending-rules__item--second'>
            <div className='lending-rules__item-wrap'>
              <h4 className='lending-rules__item-title'>ОБИРАЙ МІСІЮ</h4>
              <p className='lending-rules__item-description'>Місія - це завдання від організації. Ти можеш обрати між багатьма варіантами (цікавішу чи кориснішу). Весь перелік у розділі “МАПА”. Давай діяти, чи ти й досі читаєш ці правила?</p>
            </div>

            <img className='lending-rules__item-img' src={IconIsleWithNumberTwo} />
          </div>

          <div className='lending-rules__item lending-rules__item--third'>
            <img className='lending-rules__item-img' src={IconIsleWithNumberThree} />

            <div className='lending-rules__item-wrap'>
              <h4 className='lending-rules__item-title'>ЗДІЙСНЮЙ МІСІЮ</h4>
              <p className='lending-rules__item-description'>Ти ж не думав, що ми залишимо тебе рятувати світ наодинці? Здійснити місію і вирішити всі питання тобі допоможе координатор на місці волонтерства</p>
            </div>
          </div>

          <div className='lending-rules__item lending-rules__item--fourth'>
            <div className='lending-rules__item-wrap'>
              <h4 className='lending-rules__item-title'>ОТРИМАЙ ВИНАГОРОДУ</h4>
              <p className='lending-rules__item-description'>Після виконання завдання - отримай винагороду! Твої чесно зароблені бали підтверджує координатор, а всі виконані місії зберігаються в профілі!</p>
            </div>

            <img className='lending-rules__item-img' src={IconIsleWithNumberFour} />
          </div>

          <div className='lending-rules__item lending-rules__item--fifth'>
            <div className='lending-rules__item-wrap'>
              <h4 className='lending-rules__item-title'>РУХАЙСЯ ДАЛІ</h4>
              <p className='lending-rules__item-description'>Рятуй світ, накопичуй бали та отримуй круті плюшки</p>
            </div>

            <img className='lending-rules__item-img' src={IconIsleWithNumberFive} />
          </div>
        </div>
      </div>

      <div className='lending-advantages'>
        <div className='lending-advantages__section'>
          <h6 className='lending-advantages__title'>ПЛЮШКИ</h6>

          <div className='lending-advantages__section-content'>
            <div className='lending-advantages__section-column'>
              <div className='lending-advantages__item'>
                <img className='lending-advantages__item-img' src={IconFine} />

                <div className='lending-advantages__item-wrap'>
                  <h4 className='lending-advantages__item-title'>ЗРУЧНІСТЬ</h4>
                  <p className='lending-advantages__item-description'>ВЛАСНИЙ ПРОФIЛЬ ВОЛОНТЕРСТВА ЗАМIСТЬ ДОВГИХ ГУГЛ ФОРМ</p>
                </div>
              </div>

              <div className='lending-advantages__item'>
                <img className='lending-advantages__item-img' src={IconApply} />

                <div className='lending-advantages__item-wrap'>
                  <h4 className='lending-advantages__item-title'>КОРИСТЬ</h4>
                  <p className='lending-advantages__item-description'>БЕЗЦIННИЙ ДОСВIД ДЛЯ ТВОГО РЕЗЮМЕ</p>
                </div>
              </div>

              <div className='lending-advantages__item'>
                <img className='lending-advantages__item-img' src={IconMeet} />

                <div className='lending-advantages__item-wrap'>
                  <h4 className='lending-advantages__item-title'>ЗНАЙОМСТВА</h4>
                  <p className='lending-advantages__item-description'>ЗУСТРIЧI З ПРОАКТИВНИМИ ТА УСПIШНИМИ ЛЮДЬМИ</p>
                </div>
              </div>

              <div className='lending-advantages__item'>
                <img className='lending-advantages__item-img' src={IconChange} />

                <div className='lending-advantages__item-wrap'>
                  <h4 className='lending-advantages__item-title'>ЗМІНИ</h4>
                  <p className='lending-advantages__item-description'>ТВIЙ ОСОБИСТИЙ ВНЕСОК У КРАЩЕ МАЙБУТНЄ</p>
                </div>
              </div>
            </div>

            <div className='lending-advantages__section-column'>
              <div className='lending-advantages__item'>
                <img className='lending-advantages__item-img' src={IconParty} />

                <div className='lending-advantages__item-wrap'>
                  <h4 className='lending-advantages__item-title'>ДВІЖ</h4>
                  <p className='lending-advantages__item-description'>ВОЛОНТЕРСТВО У КРУТИХ ОРГАНI-ЗАЦIЯХ ТА НА ДРАЙВОВИХ ПОДIЯХ</p>
                </div>
              </div>

              <div className='lending-advantages__item'>
                <img className='lending-advantages__item-img' src={IconView} />

                <div className='lending-advantages__item-wrap'>
                  <h4 className='lending-advantages__item-title'>ІМІДЖ</h4>
                  <p className='lending-advantages__item-description'>ТВОЯ УСПIШНА ВОЛОНТЕРСЬКА РЕПУТАЦIЯ</p>
                </div>
              </div>

              <div className='lending-advantages__item'>
                <img className='lending-advantages__item-img' src={IconCommunity} />

                <div className='lending-advantages__item-wrap'>
                  <h4 className='lending-advantages__item-title'>ЗГУРТУВАННЯ</h4>
                  <p className='lending-advantages__item-description'>СПIЛЬНОТА ОДНОДУМЦIВ, ЩО ВМIЮТЬ РАЗОМ ВЕСЕЛИТИСЯ</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='lending-places'>
        <div className='lending-places__section'>
          <div className='lending-places__tasks'>
            <div className='lending-places__tasks-motivation'>
              <h4 className='lending-places__tasks-motivation-title'>
                ЗГАДАЙ ДИТИНСТВО
              </h4>
              <p className='lending-places__tasks-motivation-subtitle'>
                ВОЛОНТЕРЮ ДЛЯ ДIТЕЙ
              </p>
            </div>

            <div className='lending-places__tasks-item lending-places__tasks-item--first'>
              <h4 className='lending-places__tasks-item-title'>ТЕАТРАЛІЗАЦІЯ КАЗКИ «РУКАВИЧКА»</h4>
              <img className='lending-places__tasks-item-img' src={IconIsleOne} />
            </div>

            <div className='lending-places__tasks-item lending-places__tasks-item--second'>
              <h4 className='lending-places__tasks-item-title'>ВІЗИТ У ДИТЯЧИЙ БУДИНОК</h4>
              <img className='lending-places__tasks-item-img' src={IconIsleThree} />
            </div>

            <div className='lending-places__tasks-item lending-places__tasks-item--third'>
              <h4 className='lending-places__tasks-item-title'>ДОПОМОГА З ПІДГОТОВКОЮ ДО ЗНО</h4>
              <img className='lending-places__tasks-item-img' src={IconIsleTwo} />
            </div>

            <div className='lending-places__tasks-item lending-places__tasks-item--fourth'>
              <h4 className='lending-places__tasks-item-title'>ІНТЕРАКТИВНЕ НАВЧАННЯ ДЛЯ ДІТЕЙ З ІНВАЛІДНІСТЮ</h4>
              <img className='lending-places__tasks-item-img' src={IconIsleTwo} />
            </div>

            <div className='lending-places__tasks-arrows'></div>
          </div>

          <div className='lending-places__article'>
            <h4 className='lending-places__article-title'>
              МІСЦЯ ВОЛОНТЕРСТВА
            </h4>
            <p className='lending-places__article-description'>
              Завітай на будь-яку планету - це світ волонтерств за одним напрямком.
              Згодом вирушай на острівець, який подобається найбільше -
              там знаходиться твоя місія. Залишилось всього лиш натиснути “Хочу!”.
              Усі деталі можна перевірити у своєму профілі.
            </p>
            <p className='lending-places__article-description'>
              Пам’ятай: твоє основне завдання - виконати місію!
            </p>
            <p className='lending-places__article-description bg-pink'>
              Використовуй стрiлки для того, аби переглянути усi планети волонтерства у нашому свiтi
            </p>
          </div>
        </div>
        <div className='lending-places__button'>
            <p className='lending-places__button-title'>Tо як, ти з нами?</p>
            <button
              className='lending-places__button-item'
              onClick={setTab}
              style={{
                cursor: 'pointer'
              }}
            >РОЗПОЧАТИ!</button>
          </div>
      </div>

      <div className='lending-aboutUs'>
        <div className='lending-aboutUs__item'>
          <h3 className='lending-aboutUs__item-title'>
            НАША МІСІЯ
          </h3>
          <p className='lending-aboutUs__item-description'>
            Зробити волонтерство популярним, веселим i ефективним сьогоднi у Львовi, щоб воно стало корисним i впливовим для майбутнього Украiни.
          </p>
        </div>

        <div className='lending-aboutUs__item'>
          <h3 className='lending-aboutUs__item-title'>
            НАША ВІЗІЯ
          </h3>
          <p className='lending-aboutUs__item-description'>
            Ми бачимо волонтерство, що стаc можливим завдяки потужному iнструменту “ВолонтерЮ”, як спосiб розвиватися, отримувати задоволення i впливати.
          </p>
        </div>
      </div>

      <div className='lending-values'>
        <h3 className='lending-values__title'>
          НАШІ ЦІННОСТІ
        </h3>

        <div className='lending-values__container'>
          <div className='lending-values__item'>
            <img className='lending-values__item-img' src={IconGreenArrow} />
            <h3 className='lending-values__item-title'>КОРИСТЬ</h3>
            <p className='lending-values__item-description'>
              Ми вiримо, що особистiсне зростання кожного студента-волонтера - це внесок у розвиток Украiни
            </p>
          </div>

          <div className='lending-values__item'>
            <img className='lending-values__item-img' src={IconHeart} />
            <h3 className='lending-values__item-title'>ДОБРОЧИННІСТЬ</h3>
            <p className='lending-values__item-description'>
              Ми вважаємо, що намiр творити добро є невiд’ємною частиною мотивацii волонтера i основним фактором позитивного впливу на суспiльство
            </p>
          </div>

          <div className='lending-values__item'>
            <img className='lending-values__item-img' src={IconHand} />
            <h3 className='lending-values__item-title'>ПРОАКТИВНІСТЬ</h3>
            <p className='lending-values__item-description'>
              Ми впевнені, що у грі народжується ініціативне та відповідальне ставлення до всього оточуючого
            </p>
          </div>

          <div className='lending-values__item'>
            <img className='lending-values__item-img' src={IconSmiley} />
            <h3 className='lending-values__item-title'>ЗАДОВОЛЕННЯ</h3>
            <p className='lending-values__item-description'>
              Для нас дуже важливо, щоб кожен учасник отримував задоволення від того, що волонтерить. Адже процес є не менш важливим, ніж результат
            </p>
          </div>

          <div className='lending-values__item'>
            <img className='lending-values__item-img' src={IconPuzzle} />
            <h3 className='lending-values__item-title'>CПІВПРАЦЯ</h3>
            <p className='lending-values__item-description'>
              Ми переконанi, що можемо змiнюватися i змiнювати свiт на краще тiльки працюючи разом
            </p>
          </div>
        </div>
      </div>

      <div className='lending-questions'>
        <img className='lending-questions__img' src={IconSlug} />

        <div className='lending-questions__form'>
          <textarea className='lending-questions__form-textarea' placeholder='Напиши своє питання' />
          <input className='lending-questions__form-input' placeholder='Введи свою пошту' />
          <button className='lending-questions__form-button'>НАДІСЛАТИ</button>
        </div>
      </div>
    </div>
  );
};

export default Lending;
