import React, {useState, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getMyUser, login} from '../../../models'
import {TextInput} from '../../../components'
import { loadingTrue, loadingFalse } from '../../../actions/loading'
import {AUTH_FORM} from "../../../constants/index";
import {TYPE_VOLUNTEER, TYPE_ORGANIZATION} from "../../../constants/typeUsers";
import {
  setTabResetPW,
  setTabLending
} from '../../../actions/setTab'

import LoginTabCSS from './Login.module.css';
import {Link, Redirect, useHistory} from "react-router-dom";
import axios from "axios";
import {requestGetMyUser} from "../../../constants/request";
import cn from 'classnames';

export default function LoginTab({setTab = () => {}}) {
  const {authForm} = useSelector(state => state)
  const [email, setEmail] = useState(authForm.email || '')
  const [password, setPassword] = useState(authForm.password || '')
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const history = useHistory()

  const save = async () => {
    dispatch(loadingTrue())
    setError('')
    dispatch({
      type: AUTH_FORM,
      payload: {
        email,
        password
      }
    })
    if (!email) {
      dispatch(loadingFalse())
      return setError('Додайте пошту')
    }
    if (password.length < 9) {
      dispatch(loadingFalse())
      return setError('Пароль має містити більше, ніж 9 знаків')
    }
    const data = {
      email,
      password
    }

    let res = await dispatch(login(data, setError))

    // console.log(res, 'token')

    let user = await axios.get(requestGetMyUser, {
      headers: {
        'Authorization': res
      }
    })

    // console.log(user.data.userType, 'user.userType')
    // console.log(TYPE_VOLUNTEER, 'TYPE_VOLUNTEER')

    if (res) {
      dispatch(loadingFalse())

      if (user.data.userType === TYPE_VOLUNTEER) {
        history.push('/directions')
      } else {
        history.push('/profile')
      }

      dispatch(setTabLending())
    } else {
      dispatch(loadingFalse())
      setError('Невірний логін або пароль')
    }
  }

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    save()
  }, [email, password])

  return (    
  
    <form className= {cn(LoginTabCSS.formInput)} onSubmit={onSubmit}>
      <input type="submit" hidden />
      <p className={cn(LoginTabCSS.title, LoginTabCSS.tWhite)} style={{fontSize: 48, marginBottom: 30}}>ВХІД</p>
      {error && (
        <p className='title t-red'>{error}</p>
      )}
      <TextInput
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder='введи свою пошту'
        error={error}
      />
      <br />
      <TextInput
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder='введи пароль'
        type='password'
        error={error}
      />
      <br/>
      
      <div className={ cn(LoginTabCSS.avatarButtons,LoginTabCSS.avatarNextButton)}
      style={{marginTop: 20, width: 155, borderRadius: 5}}
      onClick={save}>
        <p className={ cn(LoginTabCSS.title,LoginTabCSS.black)} style={{fontSize: 36}}>УВІЙТИ</p>
      </div>
      <p style={{margin: '10px 0', color: '#fff'}}>Або</p>
      
      <div className={ cn(LoginTabCSS.row,LoginTabCSS.vCenter)}>
     
        <div className= { cn(LoginTabCSS.button,LoginTabCSS.bgWhite)} onClick={setTab}>
          <p className={ cn(LoginTabCSS.title)}>ЗАРЕЄСТРУВАТИСЯ</p>
        </div>
      </div>
      <p className={ cn(LoginTabCSS.tWhite,LoginTabCSS.text,LoginTabCSS.forgetTextPw)} onClick={() => dispatch(setTabResetPW())}>Забув пароль?</p>
    </form>
  );
}
