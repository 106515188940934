import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from 'constants'
import {getRandomArbitrary} from '../helpers'

import ImgIsle1 from '../images/Isle1.svg'
import ImgIsle2 from '../images/Isle2.svg'
import ImgIsle3 from '../images/Isle3.svg'
import ImgIsle4 from '../images/Isle4.svg'
import ImgIsle5 from '../images/Isle5.svg'
import ImgIsle6 from '../images/Isle6.svg'

const isles = [
  ImgIsle1,
  ImgIsle2,
  ImgIsle3,
  ImgIsle4,
  ImgIsle5,
  ImgIsle6
]

const getRandomIslend = () => {
  return isles[getRandomArbitrary(0, isles.length - 1)]
}

const initialState = [
  {
    img: getRandomIslend(),
    title: 'вигуляти собаку'
  },
  {
    img: getRandomIslend(),
    title: 'купити продукти бабці'
  },
  {
    img: getRandomIslend(),
    title: 'реставрація картин'
  },
  {
    img: getRandomIslend(),
    title: 'забіг'
  },
  {
    img: getRandomIslend(),
    title: 'прибирання лісу'
  },
  {
    img: getRandomIslend(),
    title: 'споруда музею'
  },
  {
    img: getRandomIslend(),
    title: 'Написати статтю у журнал'
  },
  {
    img: getRandomIslend(),
    title: 'вечір поетів'
  },
  {
    img: getRandomIslend(),
    title: 'конференція на захист тварин'
  },
  {
    img: getRandomIslend(),
    title: 'триатлон'
  },
  {
    img: getRandomIslend(),
    title: 'написання книги'
  },
  {
    img: getRandomIslend(),
    title: 'допомога пенсіонерам'
  },
  {
    img: getRandomIslend(),
    title: 'допомога інвалідам'
  }
]

const tasks = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default tasks
