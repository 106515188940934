import React, {useState, useRef, useEffect} from 'react'
import {
  Link
} from "react-router-dom"
import { Task } from '../../../components'
import DefoultAvatar from '../../../images/avatar.png'
import { useDispatch, useSelector } from 'react-redux'
import {FETCH_USERS_CLEAR} from '../../../constants/index'
import {getMyUser} from '../../../models'

// import './picker.module.css'

export default function Organization() {
  const dispatch = useDispatch()
  const {user} = useSelector(state => state)
  const avatar = user.avatar || DefoultAvatar
  const fullName = user.firstName + ' ' + user.lastName

  const linkRef = useRef()

  const logout = () => {
    const isLogout = window.confirm('Ви нас залишаєте?');
    if (isLogout) {
      dispatch({type: FETCH_USERS_CLEAR})
      localStorage.clear()
    }
  }

  useEffect(() => {
    dispatch(getMyUser())
  }, [])

  return (
    <div className='cover c-profile p-profile-volunteer'>
      <p className='title'>ПРОФІЛЬ ВОЛОНТЕРА</p>
      <Link to='/profile-change' ref={linkRef} style={{display: 'none'}}/>

      <div className='p-profile-content'>

        <div className='p-profile-content-left'>
          <div className='p-profile-div-avatar'>
            <img className='p-profile-img-avatar' src={avatar} />
            <div className='div-button-edit-profile' onClick={() => linkRef.current.click()}>
              <p className='text white'>РЕДАГУВАТИ ПРОФiЛЬ</p>
            </div>
            <br />
            <div className='div-button-logaut' onClick={logout}>
              <p className='text'>вийти з профiлю</p>
            </div>
          </div>
          <div className='p-profile-div-info-user'>
            <p className='title'>{fullName || 'ІМ’Я ВОЛОНТЕРА'}</p>
            <p className='text'>{user?.nickName|| '@nickname'}</p>
            <div className='p-profile-div-line-level'>
              {/*<p>line level</p>*/}
            </div>
          </div>
        </div>

        <div className='p-profile-content-right'>
          <div className='p-profile-div-description'>
            <p className='text'>про волонтера:</p>
            <p className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea c</p>
          </div>
        </div>
      </div>

      <p className='title'>МОЇ МІСІЇ</p>

      <div className='p-profile-tasks-list'>
        <Task />
        <Task />
        <Task />
        <Task />
        <Task />
      </div>
    </div>
  )
}
