import React, { useState } from 'react'
import IconFb from '../../images/icon-fb.svg'
import IconInstagram from '../../images/icon-instagram.svg'
import IconTelegram from '../../images/icon-telegram.svg'
import IconUsa from '../../images/IconUsaid.svg'
import IconHarkov from '../../images/IconHarkov.svg'
import IconUpshift from '../../images/IconUpshift.svg'
import IconLef from '../../images/IconLef.svg'
import IconUcu from '../../images/UCU.png'
import { requestFooterFeedback } from '../../constants/request';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import './index.css'

export default function Footer() {
  const {user} = useSelector(state => state)
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleLeaveFeedback = () => {
   if (feedback) {
    setIsLoading(true);
    axios
    .post(requestFooterFeedback, {
      feedback,
      userEmail: user.email,
    })
    .then((res) => {
      setIsLoading(false);
      setFeedback("");
    })
    .catch((err) => {
      console.dir(err.response.status, 'error')
      setIsLoading(false)
      setFeedback("");
    });
   }
  };
  return (
    <div className='footer'>
      <div style={{width: '100%', 
      maxWidth: 600
      }} className='main-div-comm-soc'>
        <div className='communication'>
          <p className='text-footer'>Маєш побажання/зауваження/рекомендації?</p>
          <div className="ui inverted input div-subscribe-email">
            <input className='input-subscribe-email' type="text" placeholder="Напиши свої враження про бета-версію" onChange={(e) => setFeedback(e.target.value)}
            value={feedback}/>
          </div>
          <br/>
          <button className='button bg-pink feedback' onClick={handleLeaveFeedback} style={{
            cursor: feedback ? 'pointer' : 'default'
          }}>
            {
              isLoading
              ? <CircularProgress size={25} color="#fff" thickness={5}/>
              : <p className='title t-white'>Готово!</p>
            }
          </button>
        </div>
        <div className='socialsWrapper'>
          <div className='social'>
            <p className='text-footer'>Ми в соціальних мережах:</p>
            <div className='footer-imgs'>
              {/*<a href='https://www.facebook.com/volonteryou' target="_blank">*/}
              {/*  <img className='icon-social' src={IconFb} />*/}
              {/*</a>*/}
              {/*<a href='https://www.instagram.com/volonteryou/' target="_blank">*/}
              {/*  <img className='icon-social' src={IconInstagram} />*/}
              {/*</a>*/}
              <img className='icon-social' src={IconFb} />
              <img className='icon-social' src={IconInstagram} />
            </div>
          </div>
          <div className='social'>
            <div className='text-footer'> <span>  Потрібна допомога?</span> <span className='boldText'>Пиши в чат бот:</span></div>
            <div className='footer-imgs'>
              {/*<a href='https://t.me/volonteryou_bot' target="_blank">*/}
              {/*  <img className='icon-social' src={IconTelegram} />*/}
              {/*</a>*/}
              <img className='icon-social' src={IconTelegram} />
            </div>
          </div>
        </div>
      </div>
      {/*<div className='partners'>*/}
      {/*  <p className='text-footer describe-text-footer'>Цей сайт було створено за підтримки Агентства США з міжнародного розвитку (USAID) в рамках програми UPSHIFT Online, яку організовує Фонд "Професійний розвиток Харкова". Зміст сайту належить виключно проєкту "ВолонтерЮ" і не обов'язково відображає погляди USAID, уряду США та ГО "Фонд "Професійний розвиток Харкова". Забороняється відтворення та використання будь-якої частини цієї продукції у будь-якому форматі, включаючи графічний, електронний, копіювання чи використання в будь-який інший спосіб без відповідного посилання на оригінальне джерело.</p>*/}
      {/*  <p className='text-footer'>Наші партнери:</p>*/}
      {/*  <div className='footer-imgs'>*/}
      {/*    <a href='https://www.usaid.gov' target="_blank">*/}
      {/*      <img className='icon-partners' src={IconUsa} />*/}
      {/*    </a>*/}
      {/*    <a href='https://www.facebook.com/perspectiva.kharkov' target="_blank">*/}
      {/*      <img className='icon-partners' src={IconHarkov} />*/}
      {/*    </a>*/}
      {/*    <a href='https://upshiftukraine.org/' target="_blank">*/}
      {/*      <img className='icon-partners' src={IconUpshift} />*/}
      {/*    </a>*/}
      {/*    <a href='http://www.lef.org.ua/ua/' target="_blank">*/}
      {/*      <img className='icon-partners' src={IconLef} />*/}
      {/*    </a>*/}
      {/*    <a href='https://ucu.edu.ua/' target="_blank">*/}
      {/*      <img className='icon-partners' src={IconUcu} />*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

