import React from 'react'
import {
  KeyboardDatePicker,
  DatePicker,
  MuiPickersUtilsProvider,
  Calendar
} from '@material-ui/pickers';
import DateUtils from '@date-io/moment';
import { Input } from 'semantic-ui-react'

import './index.css'

export default function DataPickerInput ({
  label,
  value,
  onChange,
  placeholder,
  error,
  onBlur,
  onFocus,
  showTextError = false,
  style
}) {
  let className = 'input-wrapper'

  if (error) {
    className = 'input-wrapper-error'
  }


  return (
    <div className='main-div-input' style={style}>
      {showTextError && error && (<p className={'t-red'} style={{marginBottom: 0}}>{error}</p>)}
      <div className={className}>
        <MuiPickersUtilsProvider
          utils={DateUtils}
        >
          <div style={{
            width: '100%',
          }}>
            <DatePicker
              disableFuture
              openTo="year"
              format="dd/MM/yyyy"
              label={placeholder}
              views={["year", "month", "date"]}
              value={value || null}
              onChange={onChange}
              style={{
                width: '100%',
                borderRadius: 5,
                background: 'white',
                padding: '0 10px'
              }}
              InputLabelProps={{
                style: {
                  padding: '6px 15px'
                }
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  )
}
