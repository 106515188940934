import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from 'constants'
import {getRandomArbitrary} from '../helpers'

import ImgPlanet1 from '../images/planet1.svg'
import ImgPlanet2 from '../images/planet2.svg'
import ImgPlanet3 from '../images/planet8.svg'
import ImgPlanet4 from '../images/planet4.svg'
import ImgPlanet5 from '../images/planet7.svg'

const initialState = [
  {
    img: ImgPlanet1,
    title: 'ЗГАДАЙ ДИТИНСТВО',
    desription: 'ВОЛОНТЕРЮ ДЛЯ ДIТЕЙ',
    type: 'CHILDHOOD',
    text: 'ВОЛОНТЕРЮ ДЛЯ ДIТЕЙ',
    value: 'CHILDHOOD',
    key: 'CHILDHOOD',
  },
  {
    img: ImgPlanet2,
    title: 'ВРЯТУЙ СВОЮ ПЛАНЕТУ',
    desription: 'ВОЛОНТЕРЮ ДЛЯ ЕКОЛОГiI',
    type: 'ECOLOGY',
    text: 'ВОЛОНТЕРЮ ДЛЯ ЕКОЛОГII',
    value: 'ECOLOGY',
    key: 'ECOLOGY',
  },
  {
    img: ImgPlanet3,
    title: 'ЗМІНИ СВОЮ КРАЇНУ',
    desription: 'ВОЛОНТЕРЮ ДЛЯ ГРОМАД',
    type: 'HOMELAND',
    text: 'ВОЛОНТЕРЮ ДЛЯ ГРОМАД',
    value: 'HOMELAND',
    key: 'HOMELAND',
  },
  {
    img: ImgPlanet4,
    title: 'ДАЙ ЛАПУ',
    desription: 'ВОЛОНТЕРЮ ДЛЯ ТВАРИН',
    type: 'ANIMALS',
    text: 'ВОЛОНТЕРЮ ДЛЯ ТВАРИН',
    value: 'ANIMALS',
    key: 'ANIMALS',
  },
  {
    img: ImgPlanet2,
    title: 'ТВОРИ ДОСТУПНІСТЬ',
    desription: 'ВОЛОНТЕРЮ ДЛЯ ІНКЛЮЗІЇ',
    type: 'INCLUSION',
    text: 'ВОЛОНТЕРЮ ДЛЯ ІНКЛЮЗІЇ',
    value: 'INCLUSION',
    key: 'INCLUSION',
  },
  {
    img: ImgPlanet5,
    title: 'ПРОКАЧАЙСЯ',
    desription: 'ВОЛОНТЕРЮ НА ПОДIЯХ',
    type: 'EVENTS',
    text: 'ВОЛОНТЕРЮ НА ПОДIЯХ',
    value: 'EVENTS',
    key: 'EVENTS',
  }
]

const directions = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default directions
