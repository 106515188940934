import React, {useState, useEffect} from "react"
import {
  Link,
  useLocation,
  Redirect
} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import Content from '../routers'
import {Header, Footer, СonfirmationWindow, Toasts} from '../components'
import {PAuth} from '../pages'
import {getMyUser} from '../models'
import {requestTest} from '../constants/request'
import { loadingTrue, loadingFalse } from '../actions/loading'
import {
  TAB_LENDING
} from '../constants/index'

import './index.css'

const Layout: React.FC = () => {
  const { pathname } = useLocation()
  const {user, loading} = useSelector((state: any) => state)
  const dispath = useDispatch()
  const {authTab} = useSelector((state: any) => state)


  const wakeUpServer = () => {
    dispath(loadingTrue())
    axios.get(requestTest)
      .then(() => {
        dispath(loadingFalse())
      })
  }

  useEffect(() => {
    wakeUpServer()
    if (localStorage.getItem('token') && !user.email) {
      console.log(11111)
      dispath(getMyUser())
    }
  }, [!!localStorage.getItem('token'), !user.email])


  const whereNotUse = [
    '/auth',
    '/profile-change',
  ]

  const useWhiteLogo = [
    '/profile',
    '/profile/',
    '/volonter',
    '/organization'
  ]

  const isCurrent = whereNotUse.indexOf(pathname) === -1
  const isWhiteLogo = useWhiteLogo.indexOf(pathname) === -1

  console.log(window.location.origin, 'window.location.origin- - - -')
  console.log('version 1.0.0')

  return loading ? (
        <div className='loader'>
          <Loader
            type="BallTriangle"
            color="#00BFFF"
            height={80}
            width={80} />
        </div>
      ) : (
        <div className='layout'>
          <Header isCurrent={isCurrent} isWhiteLogo={isWhiteLogo} />
          <div className='content'>
            <Content />
          </div>
          <Toasts />
          <СonfirmationWindow />
          {!isCurrent || authTab.content === TAB_LENDING && (<Footer />)}
        </div>
      )
}

export default Layout;
