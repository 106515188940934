import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import {useSelector} from "react-redux";


const initialState = {
  title: 'ВИ ВПЕВНЕНІ, ЩО ХОЧЕТЕ ЗРОБИТИ ЦЕ?',
  open: false,
  onClose: () => {},
  onOk: () => {}
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    scrollBehavior: 'smooth'
  },
  paper: {
    backgroundColor: 'rgba(196, 196, 196, 1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 1000,
    width: '100%',
  },
}));

export default function СonfirmationWindow() {
  const classes = useStyles();
  const {confirm} = useSelector(state => state)

  return (
    <Dialog
      open={confirm.open}
      onClose={confirm.onClose}
      className={classes.modal}
      scroll='body'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <div className={classes.paper}>
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <p className='title t-black'>{confirm.title}</p>
          {confirm.errorAlert ? (
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}>
              <button className='button bg-pink' onClick={confirm.onClose}>
                <p className='title t-gray'>Ok</p>
              </button>
            </div>
          ) : (
            <div className='block-buttons'>
              <button style={{marginRight: 5}} className='button bg-gray' onClick={confirm.onClose}>
                <p className='title t-white'>НІ</p>
              </button>
              <button className='button bg-pink' onClick={confirm.onOk}>
                <p className='title t-gray'>ТАК</p>
              </button>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  )
}
