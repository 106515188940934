import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {
  useHistory
} from "react-router-dom"

import './index.css'
import styles from './PTaskNotAuth.module.css'
import {getRandomIslend} from '../../helpers'
import {getMyUser} from '../../models'
import { makeStyles } from "@material-ui/core/styles";
import {ModalJoinTask} from '../PTasks/ModalJoinTask';
import MainApi from '../../utils/mainApi';
import {
  setTabLogin,
} from '../../actions/setTab'

const initLimit = 10;

const directionName = {
  'childhood': {
    type: 'CHILDHOOD',
    title: 'ЗГАДАЙ ДИТИНСТВО'
  },
  'ecology': {
    type: 'ECOLOGY',
    title: 'ВРЯТУЙ СВОЮ ПЛАНЕТУ'
  },
  'homeland': {
    type: 'HOMELAND',
    title: 'ЗМІНИ СВОЮ КРАЇНУ'
  },
  'animals': {
    type: 'ANIMALS',
    title: 'ДАЙ ЛАПУ'
  },
  'inclusion': {
    type: 'INCLUSION',
    title: 'ТВОРИ ДОСТУПНІСТЬ'
  },
  'events': {
    type: 'EVENTS',
    title: 'ПРОКАЧАЙСЯ'
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 300,
    height: 200
  },
}));

export default function PTasksNotAuth(props) {
  const mainApi = MainApi.getInstance();
  const classes = useStyles();
  const dispatch = useDispatch()
  const [tasks, setTasks] = useState([])
  const {directionType} = props.match.params;
  const taskId = props.match?.params?.taskId;
  const [limit, setLimit] = useState(initLimit);
  const history = useHistory()

  const updateTasks = () => {
    mainApi.getAllMission({direction: directionName[directionType].type, limit})
    .then((res) => {
      const readyMisson = res.data.map(mission => {
        mission.img = getRandomIslend()
        return mission
      })
      setTasks(readyMisson)
    })
  }

  useEffect(() => {
    if (directionName[directionType]) {
      updateTasks()
    }
    dispatch(getMyUser())
  }, [])

  useEffect(() => {
    if (directionName[directionType]) {
      updateTasks()
    }
  }, [limit])

  const [currentTask, setCurrentTask] = useState()
  const [currentOrg, setCurrentOrg] = useState()
  const [open, setOpen] = useState(false);

  const handleOpen = (missionId) => {
    history.push('/tasks/' + directionType + '/' + missionId)
    getMission(missionId)
  };

  const getMission = async (missionId) => {
    await mainApi.getMissionById({taskId: missionId})
      .then((res) => {
        console.log(res, 'res ----')
        console.log(res.data, 'res.data---')
        setCurrentTask(res.data)
        getOrganization(res.data)
      })
      .catch((e) => {
        console.log(e.message)
      })
  }

  const getOrganization = async (mission) => {
    setCurrentOrg({
      name: ''
    })
    setOpen(true);
    // await mainApi.getUserById({userId: mission.organizationId})
    //   .then((res) => {
    //     setCurrentOrg(res.data)
    //     setOpen(true);
    //   })
    //   .catch((e) => {
    //     console.log(e.message)
    //   })
  }

  const handleClose = () => {
    setOpen(false);
    history.push('/tasks/' + directionType)
  };

  useEffect(() => {
    if(taskId) {
      handleOpen(taskId);
    }
  }, [])

  if (!directionName[directionType]) return  null

  return (
    <div className='cover c-organizations p-tasks'>
      <h1 className='title white'>{directionName[directionType].title}</h1>

      {currentTask && (
        <ModalJoinTask
          open={open}
          handleClose={() => {
            setOpen(false);
            history.push('/')
            dispatch(setTabLogin())
          }}
          currentTask={currentTask}
          currentOrg={currentOrg}
          join={() => {
            setOpen(false);
            history.push('/')
            dispatch(setTabLogin())
          }}
        />
      )}
    </div>
  );
}
