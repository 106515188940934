import React from 'react'
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import {
  PDirections,
  PAuth,
  PProfileUser,
  PProfileUserChange,
  PTasks,
  PTasksNotAuth
} from '../pages'

export default function Content() {

  const isLogin = !!localStorage.getItem('token')

  return (
    <Switch>
      <Route path='/profile'>
        {isLogin ? <PProfileUser /> : <Redirect to='/auth' />}
      </Route>
      <Route path='/volonter'>
        {isLogin ? <PProfileUser /> : <Redirect to='/auth' />}
      </Route>
      <Route path='/organization'>
        {isLogin ? <PProfileUser /> : <Redirect to='/auth' />}
      </Route>
      <Route path='/profile-change'>
        {isLogin ? <PProfileUserChange /> : <Redirect to='/auth' />}
      </Route>
      <Route path='/tasks/:directionType/:taskId' component={isLogin ? PTasks : PTasksNotAuth} />
      <Route path='/tasks/:directionType' component={isLogin ? PTasks : PAuth} />
      <Route path='/auth'>
        {!isLogin ? <PAuth /> : <Redirect to='/directions' />}
      </Route>
      <Route path='/directions'>
        {isLogin ? <PDirections /> : <Redirect to='/auth' />}
      </Route>
      <Route path='/'>
        {/*{isLogin ? <PDirections /> : <Redirect to='/auth' />}*/}
        <PAuth />
      </Route>
    </Switch>
  );
}
