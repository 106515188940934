import axios from 'axios'
import {requestGetMyUser, requestGetMyAvatar} from '../constants/request'
import {
  fetchUserReuest,
  fetchUserSuccess,
  fetchUserFailure
} from '../actions/login'
import {FETCH_USERS_CLEAR} from "../constants";
import MainApi from '../utils/mainApi';

const getMyUser = (data) => {
  const mainApi = MainApi.getInstance();
  return async (dispatch) => {
    try {
      dispatch(fetchUserReuest())

      const res = await mainApi.getMyUser();

      const user = res.data;

      dispatch(fetchUserSuccess(user))

      return user
    } catch (e) {
      dispatch(fetchUserFailure(e))
    }
  }
}

export default getMyUser
