import React, {useEffect, useState} from 'react'
import Picker  from 'react-date-picker';
import DateUtils from '@date-io/moment';
import { DatePicker as RDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Input, Form, TextArea } from 'semantic-ui-react'
import styles from './picker.module.css'

export default function DatePicker ({
  type,
  label,
  value,
  onChange,
  placeholder,
  error,
  onBlur,
  onFocus,
  showTextError = false,
  multiline = false,
  maxHeight = 65,
  maxDate,
  minDate,
  renderInput,
  style,
}) {
  let className = styles.inputWrapper;

  if (error) {
    className = styles.inputWrapperError;
  }


  return (
    <div className={styles.root}>
      {(showTextError && error) ? (
        <p className={styles.error}>Введіть дату</p>
      ) : label ? (
        <p className={styles.label}>{label}</p>
      ) : null}
      <div className={className} style={style}>
        <MuiPickersUtilsProvider utils={DateUtils}>
          <RDatePicker
            autoOk
            disableToolbar
            variant="inline"
            value={value}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            TextFieldComponent={renderInput}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  )
}
