import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Lending from './Lending'
import LoginTab from './LoginTab'
import RegistrationTab from './RegistrationTab'
import ResetPW from './ResetPW'
import {
  setTabRegistration, setTabResetPW,
} from '../../actions/setTab'
import {
  TAB_LENDING,
  TAB_LOGIN,
  TAB_REGISTRATION,
  TAB_RESET_PW
} from '../../constants/index'
import {useHistory, useParams, useLocation } from "react-router-dom";

import './index.css'

export default function PAuth() {
  const isLogin = !!localStorage.getItem('token')
  const {authTab} = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  // console.log(search, 'searchsearchsearchsearch')

  if (search.indexOf('?token=') !== -1) {
    if (authTab.content !== TAB_RESET_PW) {
      dispatch(setTabResetPW())
    }

    const theToken = search.slice(7, search.length)


    return (
      <div className='cover c-login'>
        <ResetPW token={theToken}/>
      </div>
    )
  }


  switch (authTab.content) {
    case TAB_LENDING:
      return (<Lending setTab={() => {
        if (isLogin) {
          history.push('/directions')
        } else {
          dispatch(setTabRegistration())
        }
      }} />)
    case TAB_LOGIN:
      return (
        <div className='cover c-login'>
          <LoginTab setTab={() => dispatch(setTabRegistration())}/>
        </div>
      )
    case TAB_REGISTRATION:
      return (
        <div className='cover c-login'>
          <RegistrationTab />
        </div>
      )
    case TAB_RESET_PW:
      return (
        <div className='cover c-login'>
          <ResetPW/>
        </div>
      )
    default:
      return (
        <Lending setTab={() => {
          if (isLogin) {
            history.push('/directions')
          } else {
            dispatch(setTabRegistration())
          }
        }}/>
      )
  }
}
