import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../models";
import { getMyUser } from "../../../models";
import { Task, TextInput } from "../../../components";
import { useLocation, useHistory } from "react-router-dom";
import DefoultAvatar from "../../../images/avatar.png";
import { makeStyles } from "@material-ui/core/styles";
import { FETCH_USERS_CLEAR } from "../../../constants/index";
import IconPlus from "../../../images/icon-plus.svg";
import IconLocation from "../../../images/icon-location.svg";
import IconButton from "@material-ui/core/IconButton";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import IconPhone from "../../../images/icon-phone.svg";
import IconMessage from "../../../images/icon-message.svg";
import TextField from "@material-ui/core/TextField";
import { ModalCreateTask } from "./ModalCreateTask";
import { ModalTaskInfo } from "./ModalTaskInfo";
import { confirmCancel, confirmOk } from "../../../actions/confirm";

import { useClearCache } from "react-clear-cache";

import axios from "axios";
import {
  requestCreateTask,
  requestGetTask,
  requestGetTaskOrg,
  requestUploadAvatar,
  requestDeleteTask,
  requestGetTaskVolunteers,
} from "../../../constants/request";

import "./index.css";
import style from "./Organization.module.css";
import MainApi from "../../../utils/mainApi";
import {pushToast} from "../../../actions/toasts";
import Compress from "react-image-file-resizer";

const TYPE_UPCOMING = "upcoming";
const TYPE_COMPLETE = "completed";
const initLimit = 10;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));

export default function Organization({ forceUpdate, user, seenByVolonter }) {
  const classes = useStyles();
  const mainApi = MainApi.getInstance();
  const history = useHistory();
  const location = useLocation();
  const [isEditDescriptionMode, setIsEditDescriptionMode] = useState(false);
  const dispatch = useDispatch();
  const refUpload = useRef();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const [description, setDescription] = useState(user?.description);
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [error, setError] = useState("");
  const [avatar, setAvatar] = useState(user?.imgPath || DefoultAvatar);
  const [tasks, setTasks] = useState([]);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [visibleModalInfo, setVisibleModalInfo] = useState(location.state);
  const [currentTask, setCurrentTask] = useState();
  const [currentVolunteers, setCurrentVolunteers] = useState();
  const linkRef = useRef();
  const [missionTab, setMissionTab] = useState(TYPE_UPCOMING);
  const [limit, setLimit] = useState(initLimit);

  const logout = () => {
    const isLogout = window.confirm("Ви нас залишаєте?");
    if (isLogout) {
      dispatch({ type: FETCH_USERS_CLEAR });
      localStorage.clear();
    }
  };

  const updateTasks = async () => {
   if(!seenByVolonter) {
    const res = await mainApi.getMissionForOrganization({
      orgId: user.id,
      status: missionTab,
      limit,
    });
    setTasks(res.data);
   }
  };

  const pickImg = async (e) => {
    setError('')
    const f = e.target.files[0]
    const fd = new FormData()

    Compress.imageFileResizer(
      f,
      400,
      430,
      "WEBP",
      100,
      0,
      (resizedFile) => {

        fd.append('file', resizedFile)

        uploadImg(fd).then(() => {

          setAvatar(URL.createObjectURL(resizedFile))

          dispatch(pushToast({
            status: 'success',
            text: 'Фото завантажено'
          }))
        }).catch(() => {
          
          dispatch(pushToast({
            status: 'error',
            text: 'Помилка завантаження зображення'
          }))
        })
      },
      'blob'
    );

    // if (f.size > 1000000) {
    //   return setError('розмір картинки завеликий')
    // }
  }

  const uploadImg = async (fd) => {
    await axios({
      method: "post",
      url: requestUploadAvatar,
      data: fd,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  };

  useEffect(() => {
    dispatch(getMyUser());
  }, []);

  useEffect(() => {
    updateTasks();
  }, [missionTab, limit]);

  const createTask = async (values) => {
    // todo

    const data = {
      organizationId: user.id,
      ...values,
    };

    await axios({
      method: "post",
      url: requestCreateTask,
      data,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    updateTasks();
    setVisibleModalCreate(false);
  };

  const deleteTask = async (id) => {
    await axios({
      method: "delete",
      url: requestDeleteTask + "/" + id,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    updateTasks();
  };

  const removeTask = (id) => {
    dispatch(
      confirmOk({
        title: "ВИ ВПЕВНЕНІ, ЩО ХОЧЕТЕ ВИДАЛИТИ МІСІЮ?",
        open: true,
        errorAlert: false,
        onClose: () => {
          dispatch(confirmCancel());
        },
        onOk: () => {
          deleteTask(id);
          dispatch(confirmCancel());
          dispatch(pushToast({
            status: 'success',
            text: 'Місію скасовано!'
          }))
        },
      })
    );
  };

  const updateDesription = () => {
    const changedData = { ...user };
    changedData.description = descriptionValue;
    dispatch(updateUser(changedData));
    setDescription(descriptionValue);
    setIsEditDescriptionMode(false);
  };

  const clickTask = async (data) => {
    setCurrentVolunteers(null);
    setCurrentTask(null);
    await mainApi
      .getVolontersByMission({
        missionId: data.id,
      })
      .then((res) => {
        setCurrentVolunteers(res.data);
        setCurrentTask(data);
        setVisibleModalInfo(true);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
   if(location.state?.isComeFromVolonter) {
     clickTask(location.state.mission)
    }
  }, [])
  return (
    <div className="cover c-profile p-profile-volunteer">
      <Link to="/profile-change" ref={linkRef} style={{ display: "none" }} />
      {
       seenByVolonter
       ?  <div style={{margin: `10px 0 50px 150px`, display: 'flex', alignItems: 'center', alignSelf: 'flex-start'}}
       onClick={() => history.push({
         pathname: location.state.missionPath,
       })}>
         <div type='button' className='back-button__volonter'>
         <ArrowBackRoundedIcon classes={{root: 'zero-step__back-button-icon'}} fontSize="inherit"/>
       </div>
       <span style={{marginLeft: 10, fontFamily: 'Montserrat', fontSize: 15}}>Повернутися до місії</span>
         </div>
         : null
     }
      <div className="p-profile-content">
        <div className="p-profile-content-left">
          <div
            className="p-profile-div-avatar"
            style={{ position: "relative" }}
          >
            <img className="p-profile-img-avatar" src={avatar} />
            {seenByVolonter ? null : (
              <>
                <IconButton
                  color="primary"
                  component="span"
                  classes={{ root: "avatar-photo__wrapper" }}
                  onClick={() => refUpload.current.click()}
                >
                  <PhotoCameraIcon style={{ color: "#fff", fontSize: 26 }} />
                  <input
                    ref={refUpload}
                    style={{ display: "none" }}
                    type="file"
                    onChange={pickImg}
                    accept="image/*"
                  />
                </IconButton>
                <div
                  className="div-button-edit-profile"
                  onClick={() => linkRef.current.click()}
                >
                  <p className="text white">РЕДАГУВАТИ ПРОФiЛЬ</p>
                </div>
                <br />
                <div className="div-button-logaut" onClick={logout}>
                  <p className="text">вийти з профiлю</p>
                </div>
              </>
            )}
          </div>
          <div className="p-profile-div-info-user">
            <p className={style.mainTitle}>{user.name}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <img
                src={IconLocation}
                style={{ width: 35, height: 35, marginRight: 5 }}
              />
              <p style={{ fontFamily: "Montserrat", fontSize: 15 }}>
                {user?.address}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <img
                src={IconPhone}
                style={{ width: 35, height: 35, marginRight: 5 }}
              />
              <p style={{ fontFamily: "Montserrat", fontSize: 15 }}>
                {user?.phoneNumber}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <img
                src={IconMessage}
                style={{ width: 35, height: 35, marginRight: 5 }}
              />
              <p style={{ fontFamily: "Montserrat", fontSize: 15 }}>
                {user?.email}
              </p>
            </div>
            <div className="p-profile-div-line-level">
              {/*<p>line level</p>*/}
            </div>
          </div>
        </div>

        <div className="p-profile-content-right">
          <div className="p-profile-div-description">
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: 15,
                fontWeight: "bold",
                marginBottom: 10,
                marginLeft: 10,
              }}
            >
              Про організацію:
            </p>
            {isEditDescriptionMode ? (
              <TextField
                multiline
                rowsMax={10}
                rows={10}
                value={descriptionValue}
                onChange={(e) => setDescriptionValue(e.target.value)}
                InputProps={{ classes }}
                autoFocus
                style={{
                  fontSize: 14,
                  padding: "0 10px",
                  borderRadius: 5,
                  border: isEditDescriptionMode ? "1px solid #ebf9f6" : "none",
                }}
              />
            ) : (
              <p
                className="text"
                style={{
                  textTransform: "none",
                  padding: "0 10px",
                  hyphens: "auto",
                  maxHeight: 200,
                  overflow: "auto",
                }}
              >
                {description}
              </p>
            )}
            { seenByVolonter ? null : isEditDescriptionMode ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 10,
                  marginLeft: 10,
                }}
              >
                <p
                  className="edit-text__button"
                  style={{ marginRight: 20 }}
                  onClick={() => updateDesription()}
                >
                  Зберегти
                </p>
                <p
                  className="edit-text__button"
                  onClick={() => {
                    setIsEditDescriptionMode(false);
                    setDescriptionValue(description);
                  }}
                >
                  Скасувати
                </p>
              </div>
            ) : !isEditDescriptionMode ? (
              <p
                className="edit-text__button"
                style={{ marginLeft: 10 }}
                onClick={() => setIsEditDescriptionMode(true)}
              >
                Редагувати
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {seenByVolonter ? null : (
        <div
          className="block-btn-plus"
          onClick={() => setVisibleModalCreate(true)}
        >
          <p
            style={{
              marginBottom: 0,
              marginRight: 5,
              fontWeight: "bold",
            }}
          >
            Cтворити мiсiю
          </p>
          <img src={IconPlus} />
        </div>
      )}

      {seenByVolonter ? null : (
        <div className={style.blockMissions}>
          <p className={style.title}>МОЇ МІСІЇ</p>
          <div className={style.blockMissionButtons}>
            <div
              className={
                missionTab === TYPE_UPCOMING
                  ? style.missionTabActive
                  : style.missionTab
              }
              onClick={() => setMissionTab(TYPE_UPCOMING)}
            >
              <p
                className={
                  missionTab === TYPE_UPCOMING
                    ? style.missionTextActive
                    : style.missionText
                }
              >
                Активні
              </p>
            </div>
            <div
              className={
                missionTab === TYPE_COMPLETE
                  ? style.missionTabActive
                  : style.missionTab
              }
              style={{
                backgroundColor: missionTab !== TYPE_UPCOMING && "#35B899",
              }}
              onClick={() => setMissionTab(TYPE_COMPLETE)}
            >
              <p
                className={
                  missionTab === TYPE_COMPLETE
                    ? style.missionTextActive
                    : style.missionText
                }
              >
                Завершені
              </p>
            </div>
          </div>
          {tasks.length > 0 && (
            <div className={style.taskList}>
              {tasks.map((mission) => {
                return (
                  <Task
                    key={mission.id}
                    mission={mission}
                    deleteTask={() => removeTask(mission.id)}
                    onClick={() => clickTask(mission)}
                  />
                );
              })}
            </div>
          )}
          {limit === tasks.length && (
            <div
              className={style.missionTabActive}
              onClick={() => setLimit(limit + initLimit)}
            >
              <p className={style.missionTextActive}>Шукати ще місії +</p>
            </div>
          )}
        </div>
      )}

      <ModalCreateTask
        open={visibleModalCreate}
        handleClose={() => setVisibleModalCreate(false)}
        createTask={createTask}
      />
      {currentTask && (
        <ModalTaskInfo
          open={visibleModalInfo}
          handleClose={() => setVisibleModalInfo(false)}
          handleOpen={() => setVisibleModalInfo(true)}
          currentTask={currentTask}
          updateTasks={updateTasks}
          currentVolunteers={currentVolunteers}
          deleteTask={deleteTask}
        />
      )}
    </div>
  );
}
