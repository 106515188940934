import {combineReducers} from 'redux'
import user from './user'
import directions from './directions'
import tasks from "./tasks"
import loading from "./loading";
import confirm from "./confirm";
import stateTab from "./authTab";
import authForm from "./authForm";
import authTab from "./authTab";
import toasts from "./toasts";

export default combineReducers({
  user,
  directions,
  tasks,
  loading,
  confirm,
  stateTab,
  authForm,
  authTab,
  toasts
})
