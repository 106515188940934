import { AUTH_TAB, TAB_LENDING, SUB_TAB_REG_0, SUB_TAB_RES_0 } from '../constants/index'

// todo: types tab [len, log, reg, res]:string
// todo: types steps [regStep: string, resStep: string]

const initialState = {
  content: TAB_LENDING,
  regStep: SUB_TAB_REG_0,
  resStep: SUB_TAB_RES_0
}

const authTab = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TAB:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default authTab
