import {
  LOADING_TRUE,
  LOADING_FALSE,
} from '../constants/index'

export const loadingTrue = () => {
  return {
    type: LOADING_TRUE
  }
}

export const loadingFalse = () => {
  return {
    type: LOADING_FALSE
  }
}
