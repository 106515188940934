const getScore = (score) => {
  switch (score) {
    case 'SCORE_1':
      return 5
    case 'SCORE_2':
      return 7
    case 'SCORE_3':
      return 15
    case 'SCORE_4':
      return 25
    case 'SCORE_5':
      return 50
    default:
      return 0
  }
}

export default getScore
