
const imageUrls = [
    require('../images/aboutUs.png'),
    require('../images/alarmClock.svg'),
    require('../images/arrow.png'),
    require('../images/arrowBottom.svg'),
    require('../images/arrowTop.svg'),
    require('../images/avatar.png'),
    require('../images/bg_directions.svg'),
    require('../images/bg_login.svg'),
    require('../images/bg_organizations.svg'),
    require('../images/cloud1.svg'),
    require('../images/community.svg'),
    require('../images/crossedEye.svg'),
    require('../images/default-avatar.svg'),
    require('../images/eye.svg'),
    require('../images/greenArrow.svg'),
    require('../images/hand.svg'),
    require('../images/heart.svg'),
    require('../images/icon-apply.svg'),
    require('../images/icon-change.svg'),
    require('../images/icon-close.svg'),
    require('../images/icon-close-toast.svg'),
    require('../images/icon-error.svg'),
    require('../images/icon-fb.svg'),
    require('../images/icon-fine.svg'),
    require('../images/IconHarkov.svg'),
    require('../images/icon-instagram.svg'),
    require('../images/icon-lef.svg'),
    require('../images/IconLef.svg'),
    require('../images/icon-location.svg'),
    require('../images/icon-meet.svg'),
    require('../images/icon-message.svg'),
    require('../images/icon-phone.svg'),
    require('../images/icon-plus.svg'),
    require('../images/icon-share.svg'),
    require('../images/icon-success.svg'),
    require('../images/icon-telegram.svg'),
    require('../images/IconUcu.svg'),
    require('../images/icon-uku.svg'),
    require('../images/IconUpshift.svg'),
    require('../images/IconUsaid.svg'),
    require('../images/icon-user.svg'),
    require('../images/Isle1.svg'),
    require('../images/Isle2.svg'),
    require('../images/Isle3.svg'),
    require('../images/Isle4.svg'),
    require('../images/Isle5.svg'),
    require('../images/Isle6.svg'),
    require('../images/Isle7.svg'),
    require('../images/isleWithMissionName.svg'),
    require('../images/isleWithNumberFive.svg'),
    require('../images/isleWithNumberFour.svg'),
    require('../images/isleWithNumberOne.svg'),
    require('../images/isleWithNumberThree.svg'),
    require('../images/isleWithNumberTwo.svg'),
    require('../images/lendos1.png'),
    require('../images/light_bg.svg'),
    require('../images/login.svg'),
    require('../images/logo.svg'),
    require('../images/logo-beta-black.png'),
    require('../images/logo-beta-white.png'),
    require('../images/logoNew.svg'),
    require('../images/logoNewBlack.svg'),
    require('../images/min-img-vol.svg'),
    require('../images/party.svg'),
    require('../images/path.png'),
    require('../images/planet1.svg'),
    require('../images/planet2.svg'),
    require('../images/planet3.svg'),
    require('../images/planet4.svg'),
    require('../images/planet5.svg'),
    require('../images/planet6.svg'),
    require('../images/planet7.svg'),
    require('../images/planet8.svg'),
    require('../images/puzzle.svg'),
    require('../images/rules.png'),
    require('../images/score.svg'),
    require('../images/slug.png'),
    require('../images/slug.svg'),
    require('../images/smile-vol.svg'),
    require('../images/smiley.svg'),
    require('../images/tasks.png'),
    require('../images/UCU.png'),
    require('../images/values.png'),
    require('../images/view.svg'),
    require('../images/illuminatedIsle1.svg'),
    require('../images/illuminatedIsle2.svg'),
    require('../images/illuminatedIsle4.svg'),
];

const cached = [];

const cacheImages = () => {
    
    Promise.all(
        imageUrls.map((path) => {
            return new Promise((resolve, reject) => {

                const img = new Image();
        
                img.onload = () => resolve(path);
                img.onerror = (e) => reject(e);
        
                img.src = path;
                cached.push(img);
            });
        }),
    ).then(() => {

        console.log('LOADED');
    }).catch((e) => {

        console.log(e, 'NOT LOADED')
    });
};

export default cacheImages;