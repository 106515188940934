import React, {useEffect, useState, useMemo, useCallback} from 'react'
import { Input, Form, TextArea } from 'semantic-ui-react'

import TextCSS from './Text.module.css'
import cn from 'classnames';
import InputMask from 'react-input-mask';
import IconEye from '../../images/eye.svg';
import IconCrossedEye from '../../images/crossedEye.svg';

export default function TextInput ({
  type,
  label,
  value,
  onChange,
  placeholder,
  error,
  onBlur,
  onFocus,
  showTextError = false,
  multiline = false,
  maxHeight = 65,
  style,
  propClassName
}) {
  const [isThePasswordVisible, setThePasswordVisible] = useState(false);

  let className = TextCSS.inputWrapper

  if (error) {
    className = TextCSS.inputWrapperError
  }

  const onEyeClick = useCallback(() => {

    setThePasswordVisible(!isThePasswordVisible);
  }, [isThePasswordVisible]);

  const renderInput = useMemo(() => {
    switch (type) {

      case 'tel': {
        return (
          <InputMask
            mask="+38 (099) 999 9999"
            maskChar="x"
            alwaysShowMask
            className={cn(TextCSS.input)}
            style={{ width: '100%', padding: '9px' }}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            type={type}
          />
        )
      }

      case 'password': {
        return (
          <div style={{ alignItems: 'center', display: 'flex', position: 'relative' }}>
            <Input
              className='main-input'
              style={{height: 43}}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              onBlur={onBlur}
              onFocus={onFocus}
              type={isThePasswordVisible ? '' : 'password'}
            />
            <img
              className={cn(TextCSS.iconEye)}
              src={isThePasswordVisible ? IconEye : IconCrossedEye}
              onClick={onEyeClick}
            />
          </div>
        )
      }

      case 'custom': {
        return (
          <input
            className={cn(TextCSS.customInput, propClassName)}
            placeholder={placeholder}
            onChange={onChange}
            style={style}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        )
      }

      case 'description': {
        return (
          <textarea
            className={cn(TextCSS.customDescriptionInput, propClassName)}
            placeholder={placeholder}
            onChange={onChange}
            style={style}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        )
      }

      default: {

        if (multiline) {

          return (
            <Form>
              <TextArea
                style={{
                  maxHeight
                }}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            </Form>
          )
        } else {

          return (
            <Input
              className='main-input'
              style={{height: 43}}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              onBlur={onBlur}
              onFocus={onFocus}
              type={type}
            />
          )
        }
      }
    }
  }, [type, value, isThePasswordVisible])

  return (

    <div className={cn(TextCSS.mainDivInput)} style={style}>
      {(showTextError && error) ? (
        <p className={TextCSS.error}>{error}</p>
      ) : label ? (
        <p className={TextCSS.label}>{label}</p>
      ) : null}
      <div className={className}>
        {renderInput}
      </div>
    </div>
  )
}
