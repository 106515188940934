import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useHistory
} from "react-router-dom"
import Directions from './Directions';
import AllMissions from './AllMissions';

// import './index.css'
import axios from 'axios'

const directionName = {
  'CHILDHOOD': 'childhood',
  'ECOLOGY': 'ecology',
  'HOMELAND': 'homeland',
  'ANIMALS': 'animals',
  'INCLUSION': 'inclusion',
  'EVENTS': 'events'
}

export default function PDirections(props) {
  const [isDirection, setIsDirection] = useState(false)
  const taskId = props.match?.params?.taskId;
  const { directions } = useSelector(state => state)
  const history = useHistory()
  // history.push('/directions')

  console.log(taskId, 'taskId- - -')
  return (
    <>
      {isDirection ? <Directions setIsDirection={setIsDirection} /> : <AllMissions setIsDirection={setIsDirection} />}
    </>
  );
}
