import React, {useState} from 'react'
import MinImgVol from '../../images/min-img-vol.svg';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react'
import CloseIcon from '@material-ui/icons/Close';
import './index.css'

export default function CardVolunteer({vol, select, setSelect, showCheckbox = false, onVolonterDelete, style, 
handleVolonterLinkClick}) {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
 const history = useHistory();
  return (
    <div
      className='main-card-volunteer'
      style={style}
    >
        <CloseIcon className="card-volunteer__delete-icon"
        onClick={onVolonterDelete}/>
      <div className='card-volunteer'>
        <div className='block-card-volunteer-main-info'>
          <div className='block-img-card-volunteer' style={{marginRight: 10}}>
            <img src={MinImgVol} className='same-img-vol' />
            <div className='level-block-vol'>{vol.level}</div>
          </div>
          <div className='block-card-volunteer-fullname'>
            <p className="modal-mission_name-link"
            onClick={handleVolonterLinkClick}
            >{vol.firstName + ' ' + vol.lastName}</p>
            <p style={{color: '#878787', fontSize: 15, fontFamily: 'Montserrat', margin: 0}}>{`@${vol.nickname}`}</p>
            <p style={{color: 'rgb(192, 72, 195)', margin: 0, fontSize: 15, fontFamily: 'Montserrat'}}><i>тел. {vol.phoneNumber}</i></p>
          </div>
        </div>
        {/*<div*/}
        {/*  className='div-button-logaut'*/}
        {/*  onClick={()=>{}}*/}
        {/*  style={{*/}
        {/*    padding: 5*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <p className='text'>Профіль</p>*/}
        {/*</div>*/}
        {showCheckbox && (
        <Checkbox
          style={{
            margin: 10
          }}
          checked={select}
          onChange={setSelect}
        />
      )}
      </div>
    </div>
  )
}
