import React from 'react'
import { Checkbox } from 'semantic-ui-react'
import styles from './exparience.module.css'

export function ChooseExperience ({
   label,
   value,
   onChange,
   error,
 }) {
  let className = styles.inputWrapper;

  if (error) {
    className = styles.inputWrapperError;
  }

  return (
    <div className={styles.root}>
      {error ? (
        <p className={styles.error}>{error}</p>
      ) : (
        <p className={styles.label}>{label}</p>
      )}
      <div className={className}>
        <Checkbox
          radio
          label='5'
          className='padding-right-l'
          name='checkboxRadioGroup'
          value='SCORE_1'
          checked={value === 'SCORE_1'}
          onChange={() => onChange('SCORE_1')}
        />
        <Checkbox
          radio
          label='7'
          className='padding-right-l'
          name='checkboxRadioGroup'
          value='SCORE_2'
          checked={value === 'SCORE_2'}
          onChange={() => onChange('SCORE_2')}
        />
        <Checkbox
          radio
          label='15'
          className='padding-right-l'
          name='checkboxRadioGroup'
          value='SCORE_3'
          checked={value === 'SCORE_3'}
          onChange={() => onChange('SCORE_3')}
        />
        <Checkbox
          radio
          label='25'
          className='padding-right-l'
          name='checkboxRadioGroup'
          value='SCORE_4'
          checked={value === 'SCORE_4'}
          onChange={() => onChange('SCORE_4')}
        />
        <Checkbox
          radio
          label='50'
          className='padding-right-l'
          name='checkboxRadioGroup'
          value='SCORE_5'
          checked={value === 'SCORE_5'}
          onChange={() => onChange('SCORE_5')}
        />
      </div>
    </div>
  )
}
