import React, {useEffect, useCallback, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getMyUser} from '../../models'
import {TYPE_VOLUNTEER, TYPE_ORGANIZATION, TYPE_ADMIN} from '../../constants/typeUsers'
import Volonter from './Volonter';
import { useLocation } from "react-router-dom";
import Organization from './Organization'
import Admin from './Admin'
import {FETCH_USERS_CLEAR} from "../../constants";

export default function PProfileUser() {
  const dispatch = useDispatch()
  const location = useLocation();
  console.log('path', location.pathname)
  console.log('state', location.state)
  const {user} = useSelector(state => state)
  const [newUser, setNewUser] = useState(user || {})
  const [state, updateState] = useState(0);
  const forceUpdate = useCallback(() => {
    updateState(state + 1)
  }, []);

  const setData = async () => {
    try {
      let res = await dispatch(getMyUser())

      setNewUser(res)
    } catch (e) {
      dispatch({type: FETCH_USERS_CLEAR})
      localStorage.clear()
    }
  }

  // let View = Volonter

  useEffect(() => {
    setData()
  }, [state])


  return (
    <>
      {
       location.pathname === '/volonter' && <Volonter forceUpdate={forceUpdate} user={location.state.vol} seenByOrganization/>
      }
      {
       location.pathname === '/organization' && <Organization forceUpdate={forceUpdate} user={location.state.vol} seenByVolonter/>
      }
      {newUser.userType === TYPE_VOLUNTEER && location.pathname !== '/volonter' &&
      location.pathname !== '/organization' && (
        <Volonter forceUpdate={forceUpdate} user={newUser}/>
      )}
      {newUser.userType === TYPE_ORGANIZATION && location.pathname !== '/volonter' && 
       location.pathname !== '/organization' && (
        <Organization forceUpdate={forceUpdate} user={newUser}/>
      )}
      {newUser.userType === TYPE_ADMIN && location.pathname !== '/volonter' && 
       location.pathname !== '/organization' && (
        <Admin forceUpdate={forceUpdate} user={newUser}/>
      )}
    </>
  )
}
