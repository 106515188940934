import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextInput, Dropdown } from "../../../components";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useHistory } from 'react-router-dom';
import { pushToast } from "../../../actions/toasts";
import MainApi from '../../../utils/mainApi';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
// import {Dropdown} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import {
  TYPE_VOLUNTEER,
  TYPE_ORGANIZATION,
} from "../../../constants/typeUsers";
import { getScore } from "../../../helpers";
import moment from "moment";

import IconScore from "../../../images/score.svg";
import IconAlarmClock from "../../../images/alarmClock.svg";
import IconLocation from "../../../images/icon-location.svg";
import IconUser from "../../../images/icon-user.svg";

import Timer from "react-compound-timer";
import { confirmCancel, confirmOk } from "../../../actions/confirm";
import axios from "axios";

import styles from "./ModalJoinTask.module.css";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "rgba(196, 196, 196, 1)",
    boxShadow: theme.shadows[5],
    padding: 40,
    maxWidth: 900,
    width: "100%",
    position: "relative",
  },
}));

export function ModalJoinTask({
  open,
  handleClose,
  currentTask,
  join,
  currentOrg,
}) {
  const { user } = useSelector((state) => state);
  const history = useHistory();
  const location = useLocation();
  const mainApi = MainApi.getInstance();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isTimeJoin = new Date(currentTask.registrationClosingDateTime).getTime() > new Date().getTime();

  const save = () => {
    join();
    handleClose();
  };

  const getTime = () => {
    let duration = new Date(currentTask?.startDateTime);

    if (currentTask?.durationInHours > 0) {
      duration.setHours(duration.getHours() + currentTask?.durationInHours);
    } else {
      duration.setHours(duration.getHours() + 1);
    }

    return (
      moment(currentTask?.startDateTime).format("DD/MM/YYYY HH:mm") +
      "-" +
      moment(duration).format("HH:mm")
    );
  };

  const handleCopyLinkToMission = () => {
    dispatch(
      pushToast({
        status: "success",
        text: "Посилання скопійоване",
      })
    );
    navigator.clipboard.writeText(
      window.location.origin +
        `/tasks/${currentTask.direction.toLowerCase()}/${currentTask.id}`
    );
  };

  const onSave = () => {
    dispatch(
      confirmOk({
        title: "Точно хочеш на цю місію?",
        open: true,
        errorAlert: false,
        onClose: () => {
          dispatch(confirmCancel());
        },
        onOk: () => {
          save();
        },
      })
    );
  };

  const handleClickOrganization = (organizationId, missionId) => {
    mainApi.getCertainUser(organizationId).then(res => {
      history.push({
        pathname: '/organization',
        state: {
          vol: res.data,
          missionPath: location.pathname,
        }
      })
   }).catch(err => {
      console.log('error', err)
   })
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <IconButton
          style={{ position: "absolute", top: 10, right: 10 }}
          onClick={() => handleClose()}
        >
          <CloseIcon style={{ fontSize: 26, color: "#CF30C7" }} />
        </IconButton>
        <div className="modal-task__info-wrapper">
          <div style={{ width: "65%" }}>
            <p
              style={{
                fontSize: 36,
                fontFamily: "mrpalker",
                marginBottom: 10,
              }}
            >
              {currentTask?.name}
            </p>
            <p className="justText t-black" style={{ marginBottom: 30, }}>
            Органiзатор: <span style={{cursor: 'pointer', textDecoration: 'underline'}}
            onClick={() => handleClickOrganization(currentTask.organizationId, currentTask.id)}>{currentOrg?.name || ""}</span>
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <img
                  src={IconAlarmClock}
                  style={{
                    width: 45,
                    height: 45,
                    marginRight: 5,
                  }}
                />
                <p
                  className="mission-modal__subtitle"
                  style={{ marginLeft: 5 }}
                >
                  {getTime()}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={IconLocation}
                  style={{
                    width: 45,
                    height: 45,
                    marginRight: 5,
                  }}
                />
                <p
                  className="mission-modal__subtitle"
                  style={{ marginLeft: 5 }}
                >
                  {currentTask?.venue}
                </p>
              </div>
            </div>

            {/* <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '20px 0 10px'
          }}>
             <img src={IconUser} style={{
                width: 45,
                height: 45,
                marginRight: 5
              }} />
              <p className="mission-modal__subtitle"
                  style={{ marginLeft: 5 }}>Description</p>
          </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 20,
              }}
            >
              <p
                style={{ fontWeight: "bold", margin: "20px 0 5px" }}
                className="mission-modal__text"
              >
                Oпис мiсiї
              </p>
              <div className="mission-modal__information-block">
                {currentTask?.description}
              </div>
              <p
                style={{ fontWeight: "bold", margin: "20px 0 5px" }}
                className="mission-modal__text"
              >
                Oбов’язки волонтера
              </p>
              <div className="mission-modal__information-block">
                {currentTask?.responsibilities}
              </div>
              {currentTask?.requirements && (
                <div>
                  <p
                    style={{ fontWeight: "bold", margin: "20px 0 5px" }}
                    className="mission-modal__text"
                  >
                    Вимоги волонтера
                  </p>
                  <div className="mission-modal__information-block">
                    {currentTask?.requirements}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ width: "25%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <p
                className="title t-black"
                style={{
                  fontSize: 36,
                  fontFamily: "mrpalker",
                  marginRight: 5,
                }}
              >
                {getScore(currentTask.missionScore)}
              </p>
              <img
                src={IconScore}
                style={{
                  width: 46,
                  height: 46,
                }}
              />
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Montserrat",
                marginBottom: 0,
                fontSize: 22,
              }}
            >
              Вільних місць:
            </p>
            <p
              className="title t-black"
              style={{
                fontSize: 26,
                fontFamily: "mrpalker",
                marginLeft: 5,
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              {`${
                currentTask.maxVolunteersNumber - currentTask.volunteersOnBoard
              } / ${currentTask.maxVolunteersNumber}`}
            </p>
            {((user?.userType !== TYPE_ORGANIZATION) && isTimeJoin) && (
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <button
                  className="finish-task__button"
                  style={{ padding: 10 }}
                  onClick={onSave}
                  // disabled={
                  //   new Date(currentTask.startDateTime).getTime() >
                  //   new Date().getTime()
                  // }
                >
                  <p className="title t-white" style={{ fontSize: 36 }}>
                    Хочу
                  </p>
                </button>
              </div>
            )}
            {isTimeJoin ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  margin: "30px 0",
                  margintBottom: 0,
                }}
              >
                <Timer
                  initialTime={
                    new Date(
                      currentTask.registrationClosingDateTime
                    ).getTime() - new Date().getTime()
                  }
                  direction="backward"
                >
                  {() => (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginLeft: 5,
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Days />:
                        </p>
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Hours />:
                        </p>
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Minutes />:
                        </p>
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Seconds />
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                </Timer>
              </div>
            ) : (
              <p
                className="justText t-black"
                style={{
                  textWeight: "bold",
                  marginBottom: "10px 0",
                  display: "flex",
                  textAlign: "center",
                }}
              >
                Реєстрація на місію закрита!
              </p>
            )}
            <div
              className="mission-link__button"
              onClick={handleCopyLinkToMission}
              style={{ position: "relative" }}
            >
              <AttachFileIcon style={{ color: "#fff", fontSize: 30 }} />
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 15,
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Копіювати посилання
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
