import { AxiosResponse } from 'axios';
import HttpClient from './httpClient';
import { BASE_URL } from '../constants/request';

class MainApi extends HttpClient {
  private static classInstance?: MainApi;

  private constructor() {
    super(BASE_URL || '');
  }

  public static getInstance(): MainApi {
    if (!this.classInstance) {
      this.classInstance = new MainApi();
    }

    return this.classInstance;
  }

  public async login(data: any): Promise<AxiosResponse<any>> {
    return await this.instance.post<any>('/login', data);
  }

  public async getMyUser(): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/users');
  }

  public async getUserById({userId}: any): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/users/' + userId);
  }

  public async getMissionForOrganization({status, limit = 10}: any): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/missions/' + status + '?limit=' + limit);
  }

  public async getAllMission({direction = '', limit = 10}: any): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/missions/?direction=' + direction + '&limit=' + limit);
  }

  public async getAllMissionWithoutDirections({limit = 10}: any): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/missions/?limit=' + limit);
  }

  public async getMissionForUser({status, limit = 10}: any): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/user-missions/' + status + '?limit=' + limit);
  }

  public async requestFinishTask(taskId: string, selectVol: any): Promise<AxiosResponse<any>>{
    return await this.instance.patch<any>(`/api/0.1/missions/${taskId}`, selectVol)
  }

  public async deleteVolonterFromMission(userId: string, missionId: string): Promise<AxiosResponse<any>> {
    return await this.instance.delete<any>(`/api/0.1/user-missions?userId=${userId}&missionId=${missionId}`)
  }

  public async getCertainUser(userId: string): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>(`/api/0.1/users/${userId}`)
  }

  public async getCertainMission(missionId: string): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>(`/api/0.1/missions/${missionId}/volunteers`)
  }

  public async getMissionById({taskId}: any): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/missions/' + taskId);
  }

  public async joinToTask({missionId}: any): Promise<AxiosResponse<any>> {
    return await this.instance.post<any>('/api/0.1/user-missions/' + missionId);
  }

  public async getVolontersByMission({missionId}: any): Promise<AxiosResponse<any>> {
    return await this.instance.get<any>('/api/0.1/missions/' + missionId + '/volunteers');
  }

  public async rejectMission( missionId: string): Promise<AxiosResponse<any>> {
    return await this.instance.delete<any>('/api/0.1/user-missions/' + missionId);
  }

  public async changePassword(data: any): Promise<AxiosResponse<any>> {
    return await this.instance.patch<any>('/api/0.1/users/pwd', data);
  }
}

export default MainApi;
