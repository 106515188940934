import {
  AUTH_TAB,
  TAB_LENDING,
  TAB_LOGIN,
  TAB_REGISTRATION,
  TAB_RESET_PW,
  SUB_TAB_REG_0,
  SUB_TAB_REG_1,
  SUB_TAB_REG_2,
  SUB_TAB_REG_3,
  SUB_TAB_RES_0,
  SUB_TAB_RES_1,
  SUB_TAB_RES_2
} from '../constants/index'

export const setTab = (data) => {
  return {
    type: 'SET_TAB',
    payload: data
  }
}

export const setTabLending = () => {
  return {
    type: AUTH_TAB,
    payload: {
      content: TAB_LENDING,
      regStep: SUB_TAB_REG_0,
      resStep: SUB_TAB_RES_0
    }
  }
}

export const setTabLogin = () => {
  return {
    type: AUTH_TAB,
    payload: {
      content: TAB_LOGIN,
      regStep: SUB_TAB_REG_0,
      resStep: SUB_TAB_RES_0
    }
  }
}

export const setTabRegistration = () => {
  return {
    type: AUTH_TAB,
    payload: {
      content: TAB_REGISTRATION,
      regStep: SUB_TAB_REG_0,
      resStep: SUB_TAB_RES_0
    }
  }
}

export const setTabResetPW = () => {
  return {
    type: AUTH_TAB,
    payload: {
      content: TAB_RESET_PW,
      regStep: SUB_TAB_REG_0,
      resStep: SUB_TAB_RES_0
    }
  }
}

export const setSubTabReg0 = () => {
  return {
    type: AUTH_TAB,
    payload: {
      regStep: SUB_TAB_REG_0,
    }
  }
}

export const setSubTabReg1 = () => {
  return {
    type: AUTH_TAB,
    payload: {
      regStep: SUB_TAB_REG_1,
    }
  }
}

export const setSubTabReg2 = () => {
  return {
    type: AUTH_TAB,
    payload: {
      regStep: SUB_TAB_REG_2,
    }
  }
}

export const setSubTabReg3 = () => {
  return {
    type: AUTH_TAB,
    payload: {
      regStep: SUB_TAB_REG_3,
    }
  }
}

export const setSubTabRes0 = () => {
  return {
    type: AUTH_TAB,
    payload: {
      resStep: SUB_TAB_RES_0
    }
  }
}

export const setSubTabRes1 = () => {
  return {
    type: AUTH_TAB,
    payload: {
      resStep: SUB_TAB_RES_1
    }
  }
}

export const setSubTabRes2 = () => {
  return {
    type: AUTH_TAB,
    payload: {
      resStep: SUB_TAB_RES_2
    }
  }
}
