import React, {useState, useEffect, memo} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Link,
  useLocation,
  Redirect,
  matchPath,
  useHistory
} from "react-router-dom"
import { withRouter } from "react-router";

import './index.css'
import styles from './AllMissions.module.css'
import axios from "axios";
import {getRandomIslend} from '../../../helpers'
import {getMyUser} from '../../../models'
import { makeStyles } from "@material-ui/core/styles";
import {ModalJoinTask} from './ModalJoinTask';
import MainApi from '../../../utils/mainApi';

import { joinToTask, requestGetUserById } from '../../../constants/request'
import {pushToast} from "../../../actions/toasts";

const initLimit = 10;

const directionName = {
  'childhood': {
    type: 'CHILDHOOD',
    title: 'ЗГАДАЙ ДИТИНСТВО'
  },
  'ecology': {
    type: 'ECOLOGY',
    title: 'ВРЯТУЙ СВОЮ ПЛАНЕТУ'
  },
  'homeland': {
    type: 'HOMELAND',
    title: 'ЗМІНИ СВОЮ КРАЇНУ'
  },
  'animals': {
    type: 'ANIMALS',
    title: 'ДАЙ ЛАПУ'
  },
  'inclusion': {
    type: 'INCLUSION',
    title: 'ТВОРИ ДОСТУПНІСТЬ'
  },
  'events': {
    type: 'EVENTS',
    title: 'ПРОКАЧАЙСЯ'
  }
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 300,
    height: 200
  },
}));

function AllMissions(props) {
  const mainApi = MainApi.getInstance();
  const classes = useStyles();
  const dispatch = useDispatch()
  const [tasks, setTasks] = useState([])
  // const {directionType} = props?.match?.params;
  // const taskId = props.match?.params?.taskId;
  const [limit, setLimit] = useState(initLimit);
  const history = useHistory()

  const updateTasks = () => {
    mainApi.getAllMissionWithoutDirections({limit})
    .then((res) => {
      const readyMisson = res.data.map(mission => {
        mission.img = getRandomIslend()
        return mission
      })
      setTasks(readyMisson)
    })
  }

  useEffect(() => {
    dispatch(getMyUser())
  }, [])

  useEffect(() => {
    // if (directionName[directionType]) {
    //   updateTasks()
    // }
    updateTasks()
  }, [limit])

  const [currentTask, setCurrentTask] = useState()
  const [currentOrg, setCurrentOrg] = useState()
  const [open, setOpen] = useState(false);

  const handleOpen = (missionId) => {
    // history.push('/tasks/' + directionType + '/' + missionId)
    getMission(missionId)
  };

  const getMission = async (missionId) => {
    await mainApi.getMissionById({taskId: missionId})
      .then((res) => {
        console.log(res, 'res ----')
        console.log(res.data, 'res.data---')
        setCurrentTask(res.data)
        getOrganization(res.data)
      })
      .catch((e) => {
        console.log(e.message)
      })
  }

  const getOrganization = async (mission) => {
    await mainApi.getUserById({userId: mission.organizationId})
      .then((res) => {
        setCurrentOrg(res.data)
        setOpen(true);
      })
      .catch((e) => {
        console.log(e.message)
      })
  }

  const handleClose = () => {
    setOpen(false);
    // history.push('/tasks/' + directionType)
  };

  const join = async (missionId) => {
    await mainApi.joinToTask({missionId})
      .then(() => {
        dispatch(pushToast({
          status: 'success',
          text: 'Круто! Тебе зараховано на цю місію! Відмічай нас в своїх соцмережах! @volonteryou'
        }))
        handleClose()
      })
      .catch(() => {
        handleClose()
      })
  }

  const getTasks = () => {
    if (tasks.length === 0) return null;

    let array = tasks;
    let size = 3;
    let subarray = [];
    for (let i = 0; i <Math.ceil(array.length/size); i++){
      subarray[i] = array.slice((i*size), (i*size) + size);
    }

    return subarray.map((isles) => {
      return (
        <div key={Math.random()} className={styles.blockIsles} style={isles.length === 1 ? {width: '50%'} : {}}>
          {
            isles.length > 0 && isles.map((isle, index) => {

              const IconIsle = isle.img;
              const isMove = index === 1;

              return (
                <div key={isle.id} className={isMove ? styles.movedIsle : styles.isle} onClick={()=>handleOpen(isle.id)}>
                  <p className={styles.dateIsle}>{new Date(isle?.startDateTime).toLocaleDateString('en-GB')}</p>
                  <p className={styles.titleIsle}>{isle?.name}</p>
                  <IconIsle />
                </div>
              )
            })
          }
        </div>
      )
    })
  }

  // useEffect(() => {
    // if(taskId) {
    //   handleOpen(taskId);
    // }
  // }, [])

  // if (!directionName[directionType]) return  null

  return (
    <div className='cover c-organizations p-tasks'>
      <h1 className={styles.title}>Актуальні Місії</h1>
      <p className={styles.subtitle}>Oбери мiсiю, яка тобi подобається!</p>
      <div style={{display: 'flex', marginTop: 50}}>
        <button className='button bg-white' style={{marginRight: 5}} onClick={() => props.setIsDirection(true)}>
          <p className='title t-black'>За напрямками</p>
        </button>
        <button className='button_active_white' style={{marginRight: 5}} onClick={() => {}}>
          <p className='title t-white'>Усі місії</p>
        </button>
      </div>
      <div className={styles.blockTasks}>
        {
          getTasks()
        }
      </div>
      {limit === tasks.length && (
        <div className={styles.missionButton } onClick={() => setLimit(limit + initLimit)}>
          <p className={styles.missionTextButton}>Шукати ще місії +</p>
        </div>
      )}

      {currentTask && (
        <ModalJoinTask
          open={open}
          handleClose={handleClose}
          currentTask={currentTask}
          currentOrg={currentOrg}
          join={() => join(currentTask.id)}
        />
      )}
    </div>
  );
}

export default memo(AllMissions)
