import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import IconPointSlider from '../../../../../images/point-slider.svg'
import styles from './slide.module.css'
import cn from 'classnames';
import IconArrowLeft from "../../../../../images/icon-arrow-left-min.svg";
import IconArrowRight from "../../../../../images/icon-arrow-right-min.svg";
import TextCSS from "../../../../../components/TextInput/Text.module.css";

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 30,
    display: 'flex',
    alignItems: 'center'
  },
  thumb: {
    height: 30,
    width: 30,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 30,
    borderRadius: 20,
    color: '#35B899'
  },
  rail: {
    height: 30,
    borderRadius: 20,
    color: '#636e72'
  },
})(Slider);


export default function CustomizedSlider({
  value,
  onChange,
  error,
  label
}) {

  let className = styles.inputWrapper;

  if (error) {
    className = styles.inputWrapperError;
  }

  const onChangeCount = (text) => {
    if (text >= 100) {
      onChange(null, 100)
    } else {
      onChange(null, text)
    }
  }

  const plus = () => {
    if (!value) {
      onChange(null, 1)
    }

    if (value < 100) {
      onChange(null, value + 1)
    } else {
      onChange(null, 0)
    }
  }

  const minus = () => {
    if (value) {
      onChange(null, value - 1)
    } else {
      onChange(null, 100)
    }
  }

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.blockSlide}>
        {(error) ? (
          <p className={styles.error}>{error}</p>
        ) : label ? (
          <p className={TextCSS.label}>{label}</p>
        ) : null }
        <div className={styles.scaleVolonters}>
          <p className={styles.textScale}>1</p>
          <p className={styles.textScale}>100</p>
        </div>
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          value={value}
          onChange={onChange}
          ThumbComponent={(props) => (<div {...props}><img src={IconPointSlider} /> </div>)}
        />
      </div>
      <div className={styles.blockCalculate}>
        <button className={styles.btn} onClick={minus}>
          <img src={IconArrowLeft} />
        </button>
        <input
          value={value}
          onChange={(e) => onChangeCount(+e.target.value)}
          className={styles.inputCount}
          type='number'
          min={0}
          max={100}
        />
        <button className={styles.btn} onClick={plus}>
          <img src={IconArrowRight} />
        </button>
      </div>
    </div>
  );
}


