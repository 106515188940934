import React from 'react'
import {
  KeyboardDatePicker,
  DatePicker,
  MuiPickersUtilsProvider,
  Calendar
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {Dropdown as RDropdown, Input} from 'semantic-ui-react'
import styles from './dropdown.module.css';

export default function Dropdown ({
 label,
 value,
 onChange,
 placeholder,
 error,
 onBlur,
 onFocus,
 showTextError = false,
 options = [],
                                    search,
                                    selection
}) {
  let className = styles.inputWrapper;

  if (error) {
    className = styles.inputWrapperError;
  }


  return (
    <div className={styles.root}>
      {error ? (
        <p className={styles.error}>{error}</p>
      ) : (
        <p className={styles.label}>{label}</p>
      )}
      <div className={className}>
          <div style={{
            width: '100%'
          }}>
            <RDropdown
              style={{width: '100%'}}
              placeholder={placeholder}
              search
              selection
              onChange={onChange}
              options={options}
            />
          </div>
      </div>
    </div>
  )
}
