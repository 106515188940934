import {getRandomArbitrary} from '../helpers'
import React from 'react';

const ImgIsle1 = () => (
  <svg className="isleWithFilter" width="230" height="200" viewBox="0 0 230 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M28.481 48.4216C28.481 48.4216 37.8288 97.5804 41.5634 99.1C45.298 100.62 60.5526 113.393 60.5526 113.393L58.0598 131.336L51.7376 138.179L74.5427 156.274L122.903 179.287C123.211 179.437 123.562 179.509 123.915 179.496C124.269 179.483 124.611 179.385 124.904 179.213L160.181 159.107L143.351 128.612L185.836 116.794C187.15 116.428 187.936 115.127 187.642 113.829L183.506 96.3725L190.979 77.2131L205.999 38.7815L27.2256 41.0181L28.481 48.4216Z" fill="#7C4B27"/>
<path d="M29.1397 51.2036C29.1397 51.2036 37.8282 99.9455 41.5628 101.469C45.2974 102.993 60.552 115.762 60.552 115.762L58.0592 131.348L51.737 138.19L73.9189 156.827L121.051 178.535L123.851 179.026L79.234 130.37L87.3264 108.159L80.7875 82.3097L62.8325 42.9546H27.1572L28.6113 49.1423L29.1397 51.2036Z" fill="#663D20"/>
<path d="M173.051 97.8686L180.11 109.558L169.633 121.571L185.89 117.047C187.696 116.545 188.798 114.737 188.374 112.937L184.467 96.3723L191.937 77.213L207.313 42.9428L188.852 39.0073L173.051 97.8686Z" fill="#946D50"/>
<path d="M20.6865 31.5454L27.1939 42.5999C27.1939 42.5999 98.4947 64.4206 207.313 42.5999L210 31.5571" fill="#1E6957"/>
<path d="M115.343 42.5883C167.621 42.5883 210 37.6443 210 31.5455C210 25.4467 167.621 20.5027 115.343 20.5027C63.0658 20.5027 20.6865 25.4467 20.6865 31.5455C20.6865 37.6443 63.0658 42.5883 115.343 42.5883Z" fill="url(#paint0_linear)"/>
<path d="M177.825 126.216C174.09 127.432 164.959 132.704 164.959 132.704L169.633 144.869L175.752 149.755L188.473 137.995L177.825 126.216Z" fill="#7C4B27"/>
<path d="M182.119 130.717C179.364 132.217 176.853 133.647 176.795 133.92C176.659 134.527 176.795 139.394 176.795 139.85C176.795 140.154 177.784 144.393 178.429 147.176L188.549 137.824L182.119 130.717Z" fill="#946D50"/>
<path d="M167.037 152.775L165.998 156.831L170.564 158.655V154.193L167.037 152.775Z" fill="#663D20"/>
</g>
<defs>
<filter id="filter0_d" x="0.686523" y="0.502686" width="229.314" height="198.995" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="20.6865" y1="31.5455" x2="210" y2="31.5455" gradientUnits="userSpaceOnUse">
<stop stop-color="#1F6657"/>
<stop offset="0.13" stop-color="#206B5A"/>
<stop offset="0.29" stop-color="#227961"/>
<stop offset="0.45" stop-color="#268F6D"/>
<stop offset="0.51" stop-color="#289972"/>
<stop offset="1" stop-color="#84BDA9"/>
</linearGradient>
</defs>
</svg>
);

const ImgIsle2 = () => (
  <svg className="isleWithFilter" width="234" height="198" viewBox="0 0 234 198" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M24.125 40.3474L45.7715 84.7537L75.2091 95.2087V125.799L70.8286 145.45L104.55 177.086C104.893 177.408 105.299 177.655 105.744 177.81C106.188 177.965 106.66 178.025 107.129 177.987C107.598 177.949 108.054 177.813 108.467 177.587C108.881 177.362 109.242 177.053 109.528 176.68C115.794 168.482 134.154 145.962 149.608 141.83C168.574 136.734 183.896 126.542 183.896 126.542L189.002 88.6879L199.947 69.7258L209.806 42.419L27.8576 35.2129L24.125 40.3474Z" fill="#7C4B27"/>
<path d="M24.1123 40.4441L45.7588 84.8504L75.1964 95.317V125.892L70.8197 145.547L102.985 175.719L90.0141 115.429L93.9019 76.5988L68.0339 36.9049L27.8449 35.3096L24.1123 40.4441Z" fill="#663D20"/>
<path d="M189.668 88.4092L200.61 69.4819L211.156 38.3882L194.231 41.5324C185.695 59.2245 172.891 87.1856 172.891 88.8661C172.891 91.2901 176.771 116.529 176.771 116.529L138.918 147.224C142.732 144.583 146.589 142.542 150.279 141.551C169.244 136.455 184.562 126.26 184.562 126.26L189.668 88.4092Z" fill="#946D50"/>
<path d="M20 33.2224L26.5727 45.838C26.5727 45.838 98.6049 70.7711 208.545 45.838L214 33.2224" fill="#288A72"/>
<path d="M117 45.8381C170.572 45.8381 214 40.1899 214 33.2225C214 26.2551 170.572 20.6069 117 20.6069C63.4284 20.6069 20 26.2551 20 33.2225C20 40.1899 63.4284 45.8381 117 45.8381Z" fill="url(#paint0_linear)"/>
<path d="M47.047 95.1157L57.748 100.293C57.748 100.293 56.7741 106.442 57.748 107.41C58.7219 108.378 53.5343 117.764 52.3975 118.089C51.2606 118.415 40.5635 109.389 40.5635 109.389L47.047 95.1157Z" fill="#663D20"/>
<path d="M60.0993 120.451L56.6927 124.575C56.6927 124.575 55.3968 124.575 57.8567 127.971C60.3166 131.367 64.7049 127.971 64.7049 127.971L60.0993 120.451Z" fill="#7C4B27"/>
</g>
<defs>
<filter id="filter0_d" x="0" y="0.606934" width="234" height="197.391" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="10"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="20" y1="33.2225" x2="214" y2="33.2225" gradientUnits="userSpaceOnUse">
<stop offset="0.05" stop-color="#CAD470"/>
<stop offset="0.54" stop-color="#289972"/>
<stop offset="0.59" stop-color="#268F6D"/>
<stop offset="0.74" stop-color="#227961"/>
<stop offset="0.88" stop-color="#206B5A"/>
<stop offset="1" stop-color="#1F6657"/>
</linearGradient>
</defs>
</svg>
);

const ImgIsle4 = () => (
  <svg className="isleWithFilter" width="256" height="205" viewBox="0 0 256 205" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
  <path d="M127 169.882C127 169.882 138.674 141.981 147.75 134.841C156.826 127.702 174.987 121.206 174.987 121.206L178.877 103.684L226.864 66.7019L234 33.9341H20L47.8857 86.8373L58.2583 105.008L73.1736 110.198C73.1736 110.198 85.4957 126.418 90.0383 136.152C94.5809 145.887 107.547 166.648 108.843 164.674C109.726 163.349 112.719 174.077 114.535 181.046C114.662 181.531 114.926 181.968 115.295 182.305C115.665 182.642 116.125 182.865 116.618 182.945C117.112 183.026 117.618 182.962 118.076 182.76C118.534 182.559 118.924 182.229 119.198 181.81L127 169.882Z" fill="#7C4B27"/>
  <path d="M164.505 76.9561L144.734 86.6903L128.509 132.758L118.426 182.901C118.382 183.111 118.413 183.33 118.514 183.52C118.615 183.71 118.779 183.858 118.977 183.94C119.176 184.021 119.397 184.03 119.602 183.966C119.807 183.901 119.983 183.767 120.099 183.586L128.509 170.705C128.509 170.705 140.187 142.828 149.268 135.674C158.348 128.521 176.505 122.053 176.505 122.053L180.395 104.53L228.382 67.5486L235.518 34.7808H193.678L164.505 57.1657V76.9561Z" fill="#663D20"/>
  <path d="M48.828 87.6561L59.2007 105.823L74.1159 111.012C74.1159 111.012 86.438 127.232 90.9761 136.967C93.418 141.966 96.1096 146.839 99.0406 151.568C96.9716 144.184 94.8612 136.713 94.544 135.826C93.8957 134.018 77.362 109.319 77.362 109.319L61.7984 98.6646L26.6529 34.7759H20.9424L48.828 87.6561Z" fill="#946D50"/>
  <path d="M20 33.9482L27.3565 47.9009C27.3565 47.9009 107.956 75.475 230.961 47.9009L234 33.9482" fill="#288A72"/>
  <path d="M127 47.8961C186.094 47.8961 234 41.6513 234 33.9481C234 26.2448 186.094 20 127 20C67.9055 20 20 26.2448 20 33.9481C20 41.6513 67.9055 47.8961 127 47.8961Z" fill="url(#paint0_linear)"/>
  </g>
  <defs>
  <filter id="filter0_d" x="0" y="0" width="255.518" height="204.008" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB" >
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="10"/>
  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
  </filter>
  <linearGradient id="paint0_linear" x1="20" y1="33.9481" x2="234" y2="33.9481" gradientUnits="userSpaceOnUse">
  <stop stop-color="#53B8A0"/>
  <stop offset="0.14" stop-color="#57B99B"/>
  <stop offset="0.3" stop-color="#62BE8D"/>
  <stop offset="0.47" stop-color="#74C576"/>
  <stop offset="0.65" stop-color="#8ECF56"/>
  <stop offset="0.83" stop-color="#AFDC2D"/>
  <stop offset="1" stop-color="#D3EA00"/>
  </linearGradient>
  </defs>
  </svg>
);

const isles = [
  ImgIsle1,
  ImgIsle2,
  ImgIsle4,
]

const getRandomIslend = () => {
  return isles[getRandomArbitrary(0, isles.length - 1)]
}

export default getRandomIslend
