/*
  todo: все названные классы тут дергают стили с компонент PAuth -> RegistrationTab
  todo: обновление, переписуем на изолированые стили и от предыдущего комента скоро избавимся
 */

import React, {useRef, useState} from 'react'
import {
  Link
} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import {DataPickerInput, TextInput} from '../../components'
import { Formik } from 'formik'

import axios from 'axios'
import {
  fetchUserReuest,
  fetchUserSuccess,
  fetchUserFailure
} from '../../actions/login'
import {getMyUser, updateUser} from '../../models'
import { pushToast } from '../../actions/toasts';


import './index.css'
import * as Yup from "yup";
import moment from "moment";
import styles from './styles.module.css';
import MainApi from '../../utils/mainApi';

const TABS = {
  GENERAL_DATA: 'generalData',
  CHANGE_PASSWORD: 'changePassword',
};

export default function PProfileUserChange () {
  const [tab, setTab] = useState(TABS.GENERAL_DATA);
  
  const {user} = useSelector(state => state)
  const dispatch = useDispatch()

  const linkRef = useRef()

  const phoneRegexp = /^\+\d{2}\s\(\d{3}\)\s\d{3}\s\d{4}/;

  const save = (data) => {
    const mainApi = MainApi.getInstance();

    switch(tab) {
      case TABS.CHANGE_PASSWORD: {

        const { currentPwd, newPwd } = data;

        mainApi.changePassword({ currentPwd, newPwd }).then(() => {
          dispatch(pushToast({
            status: 'success',
            text: 'Зміни збережено'
          }))
          linkRef.current.click()
        });

        break;
      }

      case TABS.GENERAL_DATA: {

        if (user.userType === 'ORGANIZATION') {
          data.contactPerson = {
            name: data.contactName,
            phoneNumber: data.phoneNumber
          }
        }
    
        if (user.userType === 'VOLUNTEER') {
          const dateUser = data.birthDate
    
          const year = moment(dateUser).format('yyyy');
          const month = moment(dateUser).format('MM');
          const newDay = moment(dateUser).format('DD');
    
          const readyDate = year + '-' + month + '-' + newDay;
    
          data.birthDate = readyDate
        }
    
    
        dispatch(updateUser(data))
        .then(() => {
          dispatch(getMyUser())
        })
        .then(() => {
          dispatch(pushToast({
            status: 'success',
            text: 'Зміни збережено'
          }))
          linkRef.current.click()
        })

        break;
      }

      default:
        break;
    }
  }

  const initDataFirstStep =  {
    id: user.id,
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    // email: user.email || '',
    nickname: user.nickname || '',
    birthDate: user.birthDate || '',
    phoneNumber: user.phoneNumber || '',
    name: user?.name || '',
    address: user?.address || '',
    description: user.description || '',
    contactName: user?.contactPerson?.name || '',
    socialUri: user?.socialUri || ''
  }

  let validationDate = {
    phoneNumber: Yup.string()
      .matches(phoneRegexp, 'Номер телефону неправильний')
      .min(10, 'Повинен бути не менше 10 символів')
      .max(20, 'Повинен бути не більше 20 символів')
      .required('Напишіть номер телефону'),
    description: Yup.string()
      .max(1000, 'Повинен бути не більше 1000 символів')
      .required('Розкажіть про себе'),
    // email: Yup.string()
    //   .email('Пошта неправильна')
    //   .required('Напишить пошту'),
  }

  if (user.userType === 'VOLUNTEER') {
    validationDate.firstName = Yup.string()
      .max(15, 'Повинен бути не більше 15 символів')
      .required("Напишіть ім'я");
    validationDate.lastName = Yup.string()
      .max(20, 'Повинен бути не більше 20 символів')
      .required('Напишіть Прiзвище');
    validationDate.nickname = Yup.string()
      .max(20, 'Повинен бути не більше 20 символів')
      .required('Напишіть Нікнейм');
    validationDate.birthDate = Yup.string()
      .required('Напишіть дату народження');
  }

  if (user.userType === 'ORGANIZATION') {
    validationDate.name = Yup.string()
      .max(15, 'Повинен бути не більше 15 символів')
      .required("Напишіть ім'я");
    validationDate.address = Yup.string()
      .max(200, 'Повинен бути не більше 200 символів')
      .required("Напишіть адресу");
    validationDate.contactName = Yup.string()
      .max(200, 'Повинен бути не більше 200 символів')
      .required("Напишіть iм'я контактної особи");
    validationDate.socialUri = Yup.string()
      .max(200, 'Повинен бути не більше 200 символів')
      .required("Напишіть послання на веб-сайт");
  }

  if (tab === TABS.CHANGE_PASSWORD) {
    validationDate.currentPwd = Yup.string()
      .min(9, 'Повинен бути не більше 200 символів')
      .required("Пароль має містити більше, ніж 9 знаків");

    validationDate.newPwd = Yup.string()
      .min(9, 'Повинен бути не більше 200 символів')
      .required("Пароль має містити більше, ніж 9 знаків");

    validationDate.repeatNewPwd = Yup.string()
      .min(9, 'Повинен бути не більше 200 символів')
      .required("Пароль має містити більше, ніж 9 знаків")
      .oneOf([Yup.ref('newPwd'), null], 'Паролі не співпадають');
  }

  const validation = Yup.object(validationDate)

  return (
    <div className='cover c-login'>
      <div style={{ flexDirection: 'row', display: 'flex',  marginTop: 100,
            marginBottom: 100, width: '100%' }}>
      <div style={{ flex: 0.25, height: 'auto', alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
        <div className={styles.tabs}>
          <button className={styles.tabs__item} onClick={() => setTab(TABS.GENERAL_DATA)}>
            <p className={styles.tabs__text + ' ' + (tab === TABS.GENERAL_DATA ? styles.text_bold : '')}>Загальні дані</p>
          </button>
          <button className={styles.tabs__item} onClick={() => setTab(TABS.CHANGE_PASSWORD)}>
            <p className={styles.tabs__text + ' ' + (tab === TABS.CHANGE_PASSWORD ? styles.text_bold : '')}>Зміна паролю</p>
          </button>
        </div>
      </div>
      <Link to='/profile' ref={linkRef} style={{display: 'none'}}/>
      <div style={{ flex: 0.5, height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Formik
        initialValues={initDataFirstStep}
        onSubmit={(values, { setSubmitting }) => {
          save(values)
          setSubmitting(false)
        }}
        validationSchema={validation}
      >
        {({
            setFieldValue,
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            isSubmitting,
          }) => (
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: '438px',
          }}>
            <p className={styles.title}>РЕДАГУВАННЯ ПРОФІЛЮ</p>
            {
              tab === TABS.GENERAL_DATA && (
                <>
                  {user.userType === 'VOLUNTEER' && (
                    <>
                      <TextInput
                        style={{
                          marginBottom: 18,
                        }}
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        error={errors.firstName}
                        showTextError={true}
                        placeholder='Ім’я'
                      />
                      <TextInput
                        style={{
                          marginBottom: 18
                        }}
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        error={errors.lastName}
                        showTextError={true}
                        placeholder='Прiзвище'
                      />
                      <TextInput
                        style={{
                          marginBottom: 18
                        }}
                        value={values.nickname}
                        onChange={handleChange('nickname')}
                        onBlur={handleBlur('nickname')}
                        error={errors.nickname}
                        showTextError={true}
                        placeholder='Нікнейм'
                      />
                      <DataPickerInput
                        style={{
                          marginBottom: 18
                        }}
                        value={values.birthDate}
                        onChange={(data) => setFieldValue('birthDate', data)}
                        onBlur={handleBlur('birthDate')}
                        error={errors.birthDate}
                        showTextError={true}
                        placeholder='Дата народження'
                      />
                    </>
                  )}
                  {user.userType === 'ORGANIZATION' && (
                    <>
                      <TextInput
                        style={{
                          marginBottom: 18
                        }}
                        value={values.name}
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                        error={errors.name}
                        showTextError={true}
                        placeholder='Назва організації'
                      />
                      <TextInput
                        style={{
                          marginBottom: 18
                        }}
                        value={values.address}
                        onChange={handleChange('address')}
                        onBlur={handleBlur('address')}
                        error={errors.address}
                        showTextError={true}
                        placeholder='Адреса'
                      />
                      <TextInput
                        style={{
                          marginBottom: 18
                        }}
                        value={values.contactName}
                        onChange={handleChange('contactName')}
                        onBlur={handleBlur('contactName')}
                        error={errors.contactName}
                        showTextError={true}
                        placeholder="Ім'я контактної особи"
                      />
                      <TextInput
                        style={{
                          marginBottom: 18
                        }}
                        value={values.socialUri}
                        onChange={handleChange('socialUri')}
                        onBlur={handleBlur('socialUri')}
                        error={errors.socialUri}
                        showTextError={true}
                        placeholder="Послання на веб-сайт"
                      />
                    </>
                  )}
                  <TextInput
                    style={{
                      marginBottom: 18,
                    }}
                    type='tel'
                    value={values.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                    onBlur={handleBlur('phoneNumber')}
                    error={errors.phoneNumber}
                    showTextError={true}
                    placeholder='Номер телефону'
                  />
                </>
              )
            }
            {
              tab === TABS.CHANGE_PASSWORD && (
                <>
                  <TextInput
                    style={{
                      marginBottom: 18,
                    }}
                    value={values.currentPwd}
                    onChange={handleChange('currentPwd')}
                    onBlur={handleBlur('currentPwd')}
                    placeholder='Старий пароль'
                    type='password'
                    error={errors.currentPwd}
                    showTextError={true}
                  />
                  <TextInput
                    style={{
                      marginBottom: 18,
                    }}
                    value={values.newPwd}
                    onChange={handleChange('newPwd')}
                    onBlur={handleBlur('newPwd')}
                    placeholder='Новий пароль'
                    type='password'
                    error={errors.newPwd}
                    showTextError={true}
                  />
                  <TextInput
                    value={values.repeatNewPwd}
                    onChange={handleChange('repeatNewPwd')}
                    onBlur={handleBlur('repeatNewPwd')}
                    placeholder='Повторення нового паролю'
                    type='password'
                    error={errors.repeatNewPwd}
                    showTextError={true}
                  />
                </>
              )
            }
            {/* <TextInput
              multiline
              value={values.description}
              onChange={handleChange('description')}
              onBlur={handleBlur('description')}
              error={errors.description}
              showTextError={true}
              placeholder='Про себе'
            /> */}
            {/*<TextInput*/}
            {/*  value={values.email}*/}
            {/*  onChange={handleChange('email')}*/}
            {/*  onBlur={handleBlur('email')}*/}
            {/*  error={errors.email}*/}
            {/*  showTextError={true}*/}
            {/*  placeholder='Пошта'*/}
            {/*/>*/}
            {/*<br />*/}
            <div className={styles.block_buttons}>
              <button className={styles.button + ' ' + styles.bg_gray} onClick={() => linkRef.current.click()}>
                <p className={styles.button__title + ' ' + styles.text_gray}>ЗАКРИТИ</p>
              </button>
              <button disabled={isSubmitting}  className={styles.button + ' ' + styles.bg_white} onClick={handleSubmit}>
                <p className={styles.button__title + ' ' + styles.text_black}>ЗБЕРЕГТИ</p>
              </button>
            </div>
          </div>
        )}
      </Formik>
      </div>
      </div>
    </div>
  )
}
