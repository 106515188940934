import {
  CONFIRM_OK,
  CONFIRM_CANCEL
} from '../constants/index'

export const confirmOk = (data) => {
  return {
    type: CONFIRM_OK,
    payload: data
  }
}

export const confirmCancel = () => {
  return {
    type: CONFIRM_CANCEL
  }
}
