import React, {useState} from 'react'
import SmileVol from '../../images/smile-vol.svg'

import './index.css'

export default function LevelLine({levelInfo}) {

  // console.log(levelInfo)

  const currentLevelPoints = levelInfo?.currentLevelPoints
  const nextLevelPoints = levelInfo?.nextLevelPoints

  let pr = 0

  if (currentLevelPoints >= 0 && nextLevelPoints >= 0) {
    pr = currentLevelPoints / nextLevelPoints * 100
  }


  return (
    <div className='main-block-level-line' style={{height: 50, width: 338,}}>
      <div
        className='green-block-level-line'
        style={{
          width: pr + '%'
        }}
      />
      {/*<img*/}
      {/*  src={SmileVol}*/}
      {/*  className='smile-vol'*/}
      {/*  style={{*/}
      {/*    marginLeft: pr - 10 + '%'*/}
      {/*  }}*/}
      {/*/>*/}
    </div>
  )
}
