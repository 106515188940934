import { LOADING_TRUE, LOADING_FALSE } from '../constants/index'

const initialState = true

const loading = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TRUE:
      return true
    case LOADING_FALSE:
      return false
    default:
      return false
  }
}

export default loading
