import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {pushToast, removeToast} from '../../actions/toasts';
import IconClose from '../../images/icon-close-toast.svg';
import IconError from '../../images/icon-error.svg';
import IconSuccess from '../../images/icon-success.svg';
import styles from './toasts.module.css';
import toasts from "../../reducers/toasts";

export default function Toasts () {
  const toasts = useSelector((state: any) => state.toasts);
  const dispatch = useDispatch();

  if (toasts.length) {
    setTimeout(() => {
      dispatch(removeToast(0))
    }, 5000)
  }

  const close = (i: number) => {
    dispatch(removeToast(i))
  }

  if (toasts.length <= 0) return null;

  return (
    <div className={styles.list}>
      {toasts.length > 0 ? toasts.map((toast: any, index: number) => {
          const isError = toast.status === 'error';
            return (
              <div className={isError ? styles.error : styles.success}>
                <div className={styles.row}>
                  <img className={styles.icon} src={isError ? IconError : IconSuccess} />
                  <p>{toast.text}</p>
                </div>
                <img className={styles.iconClose} src={IconClose} onClick={() => close(index)} />
              </div>
            )
          }) : null}
    </div>
  )
}
