import React from 'react'
import IconClose from '../../images/icon-close.svg'
import moment from "moment";
import './index.css'

export default function Task({mission, deleteTask, onClick}) {
  console.log('mission', mission.startDateTime)
  return (
    <div className='c-task' style={{position: 'relative'}}>
      <div style={{position: 'absolute', left: '-145px', top: 15, fontSize: 16, fontFamily: 'Montserrat', color: '#878787'}}>
         {moment(mission.startDateTime).format("DD/MM/YYYY HH:mm")}
      </div>
      <div className='c-task-div-content' onClick={onClick}>
        <div className='c-task-div-info'>
          <div className='c-task-div-info-time'>
            <p className='p-profile-text-uni-sans'>{mission?.name}</p>
          </div>
          {/*<div className='c-task-div-info-name'>*/}
          {/*  <p className='p-profile-text-uni-sans'>lorem ipsum</p>*/}
          {/*</div>*/}
        </div>
      </div>
      <img className='c-task-image' src={IconClose} onClick={deleteTask}/>
    </div>
  )
}
