import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useHistory
} from "react-router-dom"

import './index.css'
import axios from 'axios'

const directionName = {
  'CHILDHOOD': 'childhood',
  'ECOLOGY': 'ecology',
  'HOMELAND': 'homeland',
  'ANIMALS': 'animals',
  'INCLUSION': 'inclusion',
  'EVENTS': 'events'
}

export default function Directions({setIsDirection}) {
  const { directions } = useSelector(state => state)
  const history = useHistory()

  const openDirection = (type) => {
    history.push('/tasks/' + directionName[type])
  }
  console.log(directions)
  return (
    <div className='cover c-directions p-directions'>
       <h1 className="direction-planets__title">Напрямки волонтерства</h1>
       <p className="direction-planets__subtitle">В якому напрямку хочеш себе спробувати?</p>
      <button className='button_active_white' style={{marginRight: 5}} onClick={() => {}}>
        <p className='title t-white'>За напрямками</p>
      </button>
      <button className='button bg-white' style={{marginRight: 5}} onClick={() => setIsDirection(false)}>
        <p className='title t-black'>Усі місії</p>
      </button>
      {
        directions.map((planet, index) => {
          return (
            <div
              className={'planet__item' + index}
              style={{flexDirection: index % 2 === 0 ? 'row' : 'row-reverse', display: 'flex',
              alignItems: 'center',
              cursor: 'pointer', width: '100%',}}
              onClick={() => {
                console.log(planet.type)
                openDirection(planet.type)
              }}
            >
              <img className='img-planet-direction' src={planet.img}/>
              <div>
                <p className='planet__item-title white' style={{textAlign: index % 2 === 0 ? 'left' : 'right'}}>{planet.title}</p>
                <p className='planet__item-text white' style={{textAlign: index % 2 === 0 ? 'left' : 'right'}}>{planet.desription}</p>
              </div>
            </div>
          )
        })
      }
    </div>
  );
}
