import React, {useState} from 'react'
import Modal from '@material-ui/core/Modal';
import {makeStyles} from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {CardVolunteer} from "../../../../components";
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MainApi from '../../../../utils/mainApi';
import {confirmCancel, confirmOk} from '../../../../actions/confirm'
import {pushToast} from '../../../../actions/toasts';

import Dialog from '@material-ui/core/Dialog';

import moment from "moment";
import {getScore} from "../../../../helpers";
import IconScore from "../../../../images/score.svg";
import IconAlarmClock from "../../../../images/alarmClock.svg";
import IconLocation from "../../../../images/icon-location.svg";
import IconUser from '../../../../images/icon-user.svg';
import Timer from "react-compound-timer";
import {TYPE_VOLUNTEER} from "../../../../constants/typeUsers";
import '../index.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    scrollBehavior: 'smooth'
  },
  paper: {
    backgroundColor: 'rgba(196, 196, 196, 1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 1000,
    minWidth: 900,
    padding: '80px 55px',
    width: '100%',
  },
  root: {
    minWidth: 900,
    position: 'relative'
  }
}));

export function ModalTaskInfo({open, deleteTask, handleClose, handleOpen, currentTask, currentVolunteers, updateTasks}) {
  const [volontersOnBoard, setVolontersOnBoard] = useState(currentVolunteers)
  const [selectVol, setSelectVol] = useState([])
  const history = useHistory();
  const mainApi = MainApi.getInstance();
  const classes = useStyles();
  const dispath = useDispatch()
  console.log('selectVol', selectVol)

  let duration = new Date(currentTask?.startDateTime);

  if (currentTask?.durationInHours > 0) {
    duration.setHours(duration.getHours() + currentTask?.durationInHours);
  } else {
    duration.setHours(duration.getHours() + 1);
  }

  const onSelectVol = (userId) => {
    const newSelectVol = [...selectVol]
    const indexVol = newSelectVol.indexOf(userId)
    const isSelect = indexVol !== -1

    if (isSelect) {
      newSelectVol.splice(indexVol, 1)
    } else {
      newSelectVol.push(userId)
    }

    setSelectVol(newSelectVol)
  }

  console.log(currentTask, 'currentTaskcurrentTask')

  const onClose = () => {
    setSelectVol([])
    handleClose()
  }

  const removeTask = () => {
    dispath(confirmOk({
      title: 'ВИ ВПЕВНЕНІ, ЩО ХОЧЕТЕ СКАСУВАТИ МІСІЮ?',
      open: true,
      errorAlert: false,
      onClose: () => {
        dispath(confirmCancel())
      },
      onOk: () => {
        deleteTask(currentTask.id)
        dispath(confirmCancel())
        onClose()
      }
    }))
  }

  const finishTask = () => {
    dispath(confirmOk({
      title: 'ВИ ВПЕВНЕНІ, ЩО ХОЧЕТЕ ЗАВЕРШИТИ МІСІЮ?',
      open: true,
      errorAlert: false,
      onClose: () => {
        dispath(confirmCancel())
      },
      onOk: async () => {
        try {
          await mainApi.requestFinishTask(currentTask.id, selectVol)
          dispath(confirmCancel())
          onClose()
          updateTasks()
          dispath(pushToast({
            status: 'success',
            text: 'Місію завершено! Бали нараховані!'
          }))
        } catch (e) {
          dispath(confirmCancel())

          dispath(confirmOk({
            title: 'Закриття місії можливе лише після її проведення!',
            open: true,
            errorAlert: true,
            onClose: () => {
              dispath(confirmCancel())
            },
          }))
        }
      }
    }))
  }

  const hadlerDeleteVolonter = (currentVol, missionId) => {
    dispath(confirmOk({
      title: `Ви впевнені, що хочете видалити волонтера @${currentVol.nickname} з цієї Місії?`,
      open: true,
      errorAlert: false,
      onClose: () => {
        dispath(confirmCancel())
      },
      onOk: () => {
        mainApi.deleteVolonterFromMission(currentVol.userId, missionId).then(res => {
          setVolontersOnBoard(volontersOnBoard.filter(item => item.userId !== currentVol.userId))
          dispath(pushToast({
            status: 'success',
            text: `Волонтера @${currentVol?.nickname} видалено з місії :(`
          }))
        })
        dispath(confirmCancel())
      }
    }))
  }

  const handleVolonterLinkClick = (volonterId, mission) => {
    mainApi.getCertainUser(volonterId).then(res => {
       history.push({
         pathname: '/volonter',
         state: {
           vol: res.data,
           mission: mission
         }
       })
    }).catch(err => {
       console.log('error', err)
    })
  }

  const handleCopyLinkToMission = () => {
    dispath(pushToast({
      status: 'success',
      text: 'Посилання скопійоване'
    }))
    navigator.clipboard.writeText(window.location.origin + `/tasks/${currentTask.direction.toLowerCase()}/${currentTask.id}`);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.modal}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        root: classes.root,
        paper: classes.root,
      }}
    >
      <div className={classes.paper}>
        <IconButton
          style={{ position: "absolute", top: 10, right: 10 }}
          onClick={() => handleClose()}
        >
          <CloseIcon style={{ fontSize: 26, color: "#CF30C7" }} />
        </IconButton>
        <div
          className="modal-task__info-wrapper"
        >
          <div style={{ width: "65%" }}>
            <p
              style={{
                fontSize: 36,
                fontFamily: "mrpalker",
              }}
            >
              {currentTask.name.toUpperCase()}
            </p>
            <div
              className="modal-task__info-content-wrapper"
            >
              <div className="modal-task__info-left-content-wrapper"
              >
                <img
                  src={IconAlarmClock}
                  className="modal-task__info-content-icon"
                />
                <p
                  className="mission-modal__subtitle"
                  style={{ marginLeft: 5 }}
                >
                  {
                    moment(currentTask.startDateTime).format("DD/MM/YYYY HH:mm") +
                  "-" +
                  moment(duration).format("HH:mm")
                  }
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <img
                  src={IconLocation}
                  className="modal-task__info-content-icon"
                />
                <p
                  className="mission-modal__subtitle"
                  style={{ marginLeft: 5 }}
                >
                  {currentTask.venue}
                </p>
              </div>
            </div>
            <p
              style={{ fontWeight: "bold", margin: "20px 0 5px" }}
              className="mission-modal__text"
            >
              Oпис мiсiї
            </p>
            <div className="mission-modal__information-block">
              {currentTask.description}
            </div>
            <p
              style={{ margin: "20px 0 5px", fontWeight: "bold" }}
              className="mission-modal__text"
            >
              Oбов’язки волонтера
            </p>
            <div className="mission-modal__information-block">
              {currentTask.responsibilities}
            </div>
            {currentTask.requirements && (
              <div>
                <p
                  className="justText t-black"
                  style={{ margin: "20px 0 5px", fontWeight: "bold" }}
                >
                  Вимоги волонтера
                </p>
                <div className="mission-modal__information-block">
                  {currentTask.requirements}
                </div>
              </div>
            )}
            {volontersOnBoard.length &&
            new Date(currentTask.registrationClosingDateTime).getTime() >
              new Date().getTime() ? (
              <p
                style={{ margin: "40px 0 5px", fontWeight: "bold" }}
                className="mission-modal__text"
              >
                Волонтери, що зголосилися
              </p>
            ) : volontersOnBoard.length &&
              new Date(currentTask.registrationClosingDateTime).getTime() <
                new Date().getTime() ? (
              <p
                style={{ margin: "40px 0 5px", fontWeight: "bold" }}
                className="mission-modal__text"
              >
                Позначте всіх волонтерів, які виконали місію:
              </p>
            ) : null}
            {volontersOnBoard.map((vol, volIndex) => {
              return (
                <div style={{marginBottom: 10}}>
                  <CardVolunteer
                  key={vol.userId}
                  vol={vol}
                  select={selectVol.indexOf(vol.userId) !== -1}
                  setSelect={() => onSelectVol(vol.userId)}
                  showCheckbox={new Date(
                    currentTask.startDateTime
                  ).getTime() < new Date().getTime()}
                  style={{
                    boxShadow: selectVol.includes(vol.userId) ? '0 0 10px rgba(207, 48, 199, 0.3)' : 'none'
                  }}
                  onVolonterDelete={() => {
                    hadlerDeleteVolonter(vol, currentTask.id)
                  }}
                  handleVolonterLinkClick={() => handleVolonterLinkClick(vol.userId, currentTask)}
                />
                </div>
              );
            })}
          </div>
          <div style={{ width: "25%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p
                className="title t-black"
                style={{
                  fontSize: 36,
                  fontFamily: "mrpalker",
                  marginRight: 5,
                }}
              >
                {getScore(currentTask.missionScore)}
              </p>
              <img
                src={IconScore}
                style={{
                  width: 46,
                  height: 46,
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 40,
              }}
            >
              <img
                src={IconUser}
                style={{
                  width: 46,
                  height: 46,
                }}
              />
              <p
                className="title t-black"
                style={{
                  fontSize: 36,
                  fontFamily: "mrpalker",
                  marginLeft: 5,
                }}
              >
                {`${currentTask.volunteersOnBoard} / ${currentTask.maxVolunteersNumber}`}
              </p>
            </div>
            {!currentTask.completed && (
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <button
                  className="finish-task__button"
                  onClick={finishTask}
                  disabled={
                    new Date(
                      currentTask.startDateTime
                    ).getTime() > new Date().getTime()
                  }
                >
                  <p className="title t-white" style={{ fontSize: 36 }}>
                    Завершити місію
                  </p>
                </button>
              </div>
            )}
            {new Date(currentTask.registrationClosingDateTime).getTime() >
            new Date().getTime() ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: 20,
                  margintBottom: 0,
                }}
              >
                <Timer
                  initialTime={
                    new Date(
                      currentTask.registrationClosingDateTime
                    ).getTime() - new Date().getTime()
                  }
                  direction="backward"
                >
                  {() => (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginLeft: 5,
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Days />:
                        </p>
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Hours />:
                        </p>
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Minutes />:
                        </p>
                        <p
                          className="title t-black"
                          style={{
                            fontSize: 36,
                            fontFamily: "mrpalker",
                            marginBottom: 0,
                          }}
                        >
                          <Timer.Seconds />
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                </Timer>
              </div>
            ) : (
              <p
                className="justText t-black"
                style={{
                  textWeight: "bold",
                  marginBottom: '10px 0',
                  display: "flex",
                  textAlign: "center",
                }}
              >
                Реєстрація на місію закрита!
              </p>
            )}
            {new Date(currentTask.startDateTime).getTime() >
            new Date().getTime() ? (
              <p
                style={{
                  textDecoration: "underline",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 15,
                  fontFamily: "Montserrat",
                  cursor: "pointer",
                  color: "#CF30C7",
                }}
                onClick={removeTask}
              >
                Скасувати місію
              </p>
            ) : null}
            <div className="mission-link__button" onClick={handleCopyLinkToMission} style={{position: 'relative'}}>
              <AttachFileIcon style={{color: '#fff', fontSize: 30}}/>
              <span style={{
                fontFamily: 'Montserrat',
                fontSize: 15,
                color: '#fff',
                textAlign: 'center'
              }}>Копіювати посилання</span>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
