import React, {useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {TextInput, Dropdown, DatePicker, ClockPicker} from "../../../../components";
import { ChooseExperience } from './ChooseExperience'
import {useSelector, useDispatch} from "react-redux";

import CustomizedSlider from './CustomizedSlider'

import Dialog from '@material-ui/core/Dialog';
import { PickerClock } from './PickerClock';

import { Formik } from 'formik'
import * as Yup from 'yup'
import moment from "moment";
import {pushToast} from "../../../../actions/toasts";
import styles from './ModalCreateTask.module.css'

const timeTask = [
  {
    text: 1,
    value: 1,
  },
  {
    text: 2,
    value: 2,
  },
  {
    text: 3,
    value: 3,
  },
  {
    text: 4,
    value: 4,
  },
  {
    text: 5,
    value: 5,
  },
  {
    text: 6,
    value: 6,
  },
  {
    text: 7,
    value: 7,
  },
  {
    text: 8,
    value: 8,
  },
]

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    scrollBehavior: 'smooth'
  },
  paper: {
    backgroundColor: 'rgba(196, 196, 196, 1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 1000,
    width: '100%',
  },
}));

export function ModalCreateTask({open, handleClose, createTask}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const {directions} = useSelector(state => state)

  const [value, onChange] = useState(0);
  const [exp, setExp] = useState();

  const initData = {
    name: '',
    description: '',
    responsibilities: '',
    requirements: '',
    direction: '',
    startDate: '',
    startTime: '',
    regDate: '',
    regTime: '',
    venue: '',
    missionScore: '',
    volunteersOnBoard: 0,
    maxVolunteersNumber: 0,
    durationInHours: ''
  }

  const initVal = {
    name: Yup.string()
      .max(200, 'Повинен бути не більше 20 символів')
      .required('Назвіть місію'),
    responsibilities: Yup.string()
      .max(200, 'Повинен бути не більше 200 символів')
      .required("Обов'язки волонтера"),
    direction: Yup.string()
      .required('Виберіть направлення місії'),
    startDate: Yup.string()
      .required('Введіть дату'),
    startTime: Yup.string()
      .required('Введіть час'),
    regDate: Yup.string()
      .required('Введіть дедлайн дня реєстрації'),
    regTime: Yup.string()
      .required('Введіть дедлайн часу реєстрації'),
    venue: Yup.string()
      .required('Введіть адресу'),
    missionScore: Yup.string()
      .required('Введіть кількість балів за проходження місії'),
    maxVolunteersNumber: Yup.number()
      .min(1, 'Вкажіть кількість волонтерів')
      .required('Вкажіть кількість волонтерів'),
    durationInHours: Yup.string()
      .required('Вкажіть кількість часу'),
  }

  const validation = Yup.object(initVal)

  const onSubmit = (values) => {
    const yearStart = moment(values.startDate).format('yyyy');
    const monthStart = moment(values.startDate).format('MM');
    const dayStart = moment(values.startDate).format('DD');

    const readyDateStart = yearStart + '-' + monthStart + '-' + dayStart;

    values.startDateTime = readyDateStart + ' ' + values.startTime

    const yearReg = moment(values.regDate).format('yyyy');
    const monthReg = moment(values.regDate).format('MM');
    const dayReg = moment(values.regDate).format('DD');

    const readyDateReg = yearReg + '-' + monthReg + '-' + dayReg;

    values.registrationClosingDateTime = readyDateReg + ' ' + values.regTime

    delete values.startDate
    delete values.startTime
    delete values.regDate
    delete values.regTime

    createTask(values)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='body'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title">Створення місії</h2>
        <Formik
          initialValues={initData}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values)
            handleClose()
            dispatch(pushToast({
              status: 'success',
              text: 'Місію створено!'
            }))
            setSubmitting(false)
          }}
          validationSchema={validation}
        >
          {({
              setFieldValue,
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              errors,
              isSubmitting,
              touched
            }) => (
              <>
                <TextInput
                  label='Назва місії*'
                  value={values.name}
                  onChange={handleChange('name')}
                  error={touched.name && errors.name}
                  showTextError={true}
                  placeholder='Назва місії'
                  type='custom'
                  style={{
                    width: '100%'
                  }}
                />
                <TextInput
                  label='Опис мiсii'
                  type='description'
                  value={values.description}
                  onChange={handleChange('description')}
                  error={touched.description && errors.description}
                  showTextError={true}
                  placeholder="Опис місії"
                  multiline={true}
                  style={{
                    width: '100%'
                  }}
                />
                <TextInput
                  label='Обов’язки волонтера*'
                  value={values.responsibilities}
                  onChange={handleChange('responsibilities')}
                  error={touched.responsibilities && errors.responsibilities}
                  showTextError={true}
                  placeholder="Обов'язки волонтера"
                  type='description'
                  multiline={true}
                  style={{
                    width: '100%'
                  }}
                />
                <TextInput
                  label='Вимоги до волонтера'
                  type='description'
                  value={values.requirements}
                  onChange={handleChange('requirements')}
                  error={touched.requirements && errors.requirements}
                  showTextError={true}
                  placeholder="Вимоги до волонтера (необхідні довідки, маски і т.д.) "
                  multiline={true}
                />
                <Dropdown
                  style={{width: '100%'}}
                  placeholder='Тип місії'
                  label='Тип місії*'
                  search
                  selection
                  onChange={(e, data) => {
                    console.log(data.value)
                    setFieldValue('direction', data.value)
                  }}
                  error={touched.direction && errors.direction}
                  showTextError={true}
                  options={directions}
                />
                <div
                  className='row-m'
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start'
                  }}
                >
                  <DatePicker
                    onChange={(data) => setFieldValue('startDate', data)}
                    label='Дата*'
                    showTextError={true}
                    error={touched.startDate && errors.startDate}
                    value={values.startDate}
                    minDate={new Date()}
                    renderInput={(params) => <input className={styles.inputPicker} {...params} />}
                  />
                  <PickerClock
                    label='Час*'
                    value={values.startTime}
                    onChange={(data) => setFieldValue('startTime', data)}
                    error={touched.startTime && errors.startTime}
                    showTextError={true}
                  />
                  <TextInput
                    type='custom'
                    label='Адреса*'
                    value={values.venue}
                    onChange={handleChange('venue')}
                    error={touched.venue && errors.venue}
                    showTextError={true}
                    placeholder="Введіть адресу"
                  />
                </div>
                <p style={{marginBottom: 0}}>Дедлайн реєстрації на місію</p>
                <div
                  className='row-m'
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    // width: 200,
                  }}
                >
                  <DatePicker
                    onChange={(data) => setFieldValue('regDate', data)}
                    label='Дата*'
                    showTextError={true}
                    error={touched.regDate && errors.regDate}
                    value={values.regDate}
                    maxDate={values.startDate}
                    minDate={new Date()}
                    renderInput={(params) => <input className={styles.inputPicker} {...params} />}
                  />
                  <PickerClock
                    label='Час*'
                    value={values.regTime}
                    onChange={(data) => setFieldValue('regTime', data)}
                    error={(touched.regTime && errors.regTime) || (touched.regTime && (values.startDate === values.regDate && values.startTime < values.regTime))}
                    showTextError={true}
                  />
                </div>
                <ChooseExperience
                  value={values.missionScore}
                  onChange={(data) => setFieldValue('missionScore', data)}
                  error={touched.missionScore && errors.missionScore}
                  label={'Кількість балів за проходження місії*'}
                />
                 <CustomizedSlider
                   label='Кількість волонтерів*'
                   error={touched.maxVolunteersNumber && errors.maxVolunteersNumber}
                    value={values.maxVolunteersNumber}
                    onChange={(event, value) => setFieldValue('maxVolunteersNumber', value)}
                 />
                <Dropdown
                  style={{width: '100%'}}
                  placeholder='Вкажіть тривалість місії'
                  label='Вкажіть тривалість місії'
                  search
                  selection
                  onChange={(e, data) => setFieldValue('durationInHours', data.value)}
                  error={touched.durationInHours && errors.durationInHours}
                  options={timeTask}
                />
                <div className='block-buttons'>
                  <button className='button bg-gray' onClick={handleClose}>
                    <p className='title t-gray'>ЗАКРИТИ</p>
                  </button>
                  <button  disabled={isSubmitting} className='button bg-pink' onClick={handleSubmit}>
                    <p className='title t-white'>ДОДАТИ МІСІЮ</p>
                  </button>
                </div>
              </>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}
