import {
  REMOVE_TOAST,
  PUSH_NEW_TOAST,
} from '../constants';

export const pushToast = (data) => {
  return {
    type: PUSH_NEW_TOAST,
    payload: data,
  }
}

export const removeToast = (index) => {
  return {
    type: REMOVE_TOAST,
    payload: {
      index
    },
  }
}
