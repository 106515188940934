import React, {useState} from 'react'
import Modal from '@material-ui/core/Modal';
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {TextInput, Dropdown, DatePicker, ClockPicker, WrapperBlock} from "../../../../components";
import {useSelector} from "react-redux";

import Dialog from '@material-ui/core/Dialog';

import moment from "moment";
import {getScore} from "../../../../helpers";
import IconScore from "../../../../images/score.svg";
import IconAlarmClock from "../../../../images/alarmClock.svg";
import IconLocation from "../../../../images/icon-location.svg";
import Timer from "react-compound-timer";
import {TYPE_VOLUNTEER} from "../../../../constants/typeUsers";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    scrollBehavior: 'smooth'
  },
  paper: {
    backgroundColor: 'rgba(196, 196, 196, 1)',
    boxShadow: theme.shadows[5],
    padding: 50,
    maxWidth: '1000px',
    minWidth: 900,
    width: '100%',
  },
}));

export function ModalTaskInfo({open, handleClose, currentTask}) {
  const classes = useStyles();

  let duration = new Date(currentTask?.startDateTime);

  if (currentTask?.durationInHours > 0) {
    duration.setHours(duration.getHours() + currentTask?.durationInHours);
  } else {
    duration.setHours(duration.getHours() + 1);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='body'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="900px"
    >
      <div className={classes.paper}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20,
        }}>
          <IconButton
          style={{ position: "absolute", top: 10, right: 10 }}
          onClick={() => handleClose()}
        >
          <CloseIcon style={{ fontSize: 26, color: "#CF30C7" }} />
        </IconButton>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <p style={{ fontSize: 36,
                fontFamily: "mrpalker"}}>{currentTask.name}</p>
            {/*<p className='justText t-black'>{'Органiзатор: ' + currentOrg.name}</p>*/}
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <p style={{
                  fontSize: 36,
                  fontFamily: "mrpalker",
                  margin: '0 5px 0'
                }}>{getScore(currentTask.missionScore)}</p>
            <img src={IconScore} style={{
              width: 45,
              height: 45
            }} />
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 20
          }}>
            <img src={IconAlarmClock} style={{
              width: 45,
              height: 45,
              marginRight: 5
            }} />
            <p className="mission-modal__subtitle"
                  style={{ marginLeft: 5 }}>
              {
                moment(currentTask.startDateTime).format("DD/MM/YYYY HH:mm") +
                "-" +
                moment(duration).format("HH:mm")
              }</p>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
            <img src={IconLocation} style={{
              width: 45,
              height: 45,
              marginRight: 5
            }} />
            <p className="mission-modal__subtitle"
                  style={{ marginLeft: 5 }}>{currentTask.venue}</p>
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: 20
        }}>
          <p
             style={{ fontWeight: "bold", margin: "20px 0 5px" }}
             className="mission-modal__text"
          >Oпис мiсiї</p>
          <div className="mission-modal__information-block" style={{width: '90%'}}>
            {currentTask.description}
          </div>
          <p
             style={{ fontWeight: "bold", margin: "20px 0 5px" }}
             className="mission-modal__text"
          >Oбов’язки волонтера</p>
          <div className="mission-modal__information-block" style={{width: '90%'}}>
            {currentTask.responsibilities}
          </div>
          {currentTask.requirements && (
            <div>
              <p
                 style={{ fontWeight: "bold", margin: "20px 0 5px" }}
                 className="mission-modal__text"
              >Вимоги волонтера</p>
              <div className="mission-modal__information-block" style={{width: '90%'}}>
                {currentTask.requirements}
              </div>
            </div>
          )}
          {/* {new Date(currentTask.registrationClosingDateTime).getTime() > new Date().getTime() ? (
            <div>
              <p
                className='justText t-black'
                style={{
                  textWeight: 'bold',
                  marginBottom: 0
                }}
              >Реченець рестрацii:</p>
              <Timer
                initialTime={new Date(currentTask.registrationClosingDateTime).getTime() - new Date().getTime()}
                direction='backward'
              >
                {() => (
                  <React.Fragment>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p className='title t-black' style={{marginRight: 5}}><Timer.Days />{' дня '}</p>
                      <p className='title t-black' style={{marginRight: 5}}><Timer.Hours />{' год '}</p>
                      <p className='title t-black' style={{marginRight: 5}}><Timer.Minutes />{' хв. '}</p>
                      <p className='title t-black' style={{marginRight: 5}}><Timer.Seconds />{' сек. '}</p>
                    </div>
                  </React.Fragment>
                )}
              </Timer>
            </div>
          ) : (
            <p
              className='justText t-black'
              style={{
                textWeight: 'bold',
                marginBottom: 0
              }}
            >Реєстрація на місію закрита!</p>
          )} */}
        </div>
        {/*{user.userType === TYPE_VOLUNTEER && (*/}
        {/*  <div className='block-buttons'>*/}
        {/*    <button className='button bg-gray' onClick={handleClose}>*/}
        {/*      <p className='title t-gray'>НЕ ХОЧУ</p>*/}
        {/*    </button>*/}
        {/*    <button className='button bg-pink' onClick={save}>*/}
        {/*      <p className='title t-white'>ХОЧУ</p>*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </Dialog>
  )
}



