import React, {useRef} from "react"
import {
  useLocation,
  Redirect,
  Link,
  useHistory
} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import {FETCH_USERS_CLEAR} from '../../constants/index'
import {requestGetMyUser} from '../../constants/request'
import logo from '../../images/logoNewBlack.svg'
import logoWhite from '../../images/logo-beta-white.png'
import logoBlack from '../../images/logo-beta-black.png'
import axios from 'axios'
import {setTabLogin, setTabLending} from '../../actions/setTab'
import {
  TAB_LENDING,
  TAB_LOGIN,
  TAB_REGISTRATION,
  TAB_RESET_PW
} from '../../constants/index'
import './index.css'

export default function Header({isCurrent, isWhiteLogo}) {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const {stateTab} = useSelector(state => state)
  const {authTab} = useSelector(state => state)
  const logoRef = useRef()
  const profileRef = useRef()
  const PATH_AUTH = '/auth'

  const history = useHistory()

  const isLogin = !!localStorage.getItem('token')

  // console.log(pathname, 'pathnameee')

  return (
    <div className='header'>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {/*<p className='title' style={{marginBottom: 0, marginRight: 5, color: isWhiteLogo ? 'white' : 'black'}}>ВОЛОНТЕР</p>*/}
        {isWhiteLogo ? (
          <img src={logoWhite} className='logo' onClick={() => {
            history.push('/')
            dispatch(setTabLending())
          }} />
        ) : (
          <img src={logoBlack} className='logo' onClick={() => {
            history.push('/')
            dispatch(setTabLending())
          }} />
        )}
      </div>
      {!isLogin && authTab.content !== TAB_LOGIN ? (
        <div className='block-btn-header'>
          {/*<button className='button lend-btn-border' onClick={() => profileRef.current.click()}>*/}
          {/*  <p className='title t-white'>РЕЙТИНГ</p>*/}
          {/*</button>*/}
          {/*<button className='button lend-btn-border' onClick={() => profileRef.current.click()}>*/}
          {/*  <p className='title t-white'>МАПА</p>*/}
          {/*</button>*/}
          <button className='button lend-btn-border' onClick={() => {
            history.push('/')
            dispatch(setTabLogin())
          }}>
            <p className='title t-white'>ВХІД</p>
          </button>
        </div>
      ) : isCurrent && authTab.content !== TAB_LOGIN ? (
        <div className='block-btn-header'>
          <button className='button bg-green' style={{marginRight: 5}} onClick={() => history.push('/directions')}>
            <p className='title t-white'>МАПА</p>
          </button>
          <button className='button bg-green' onClick={() => history.push('/profile')}>
            <p className='title t-white'>ПРОФIЛЬ</p>
          </button>
        </div>
      ) : (<div/>)}
    </div>
  );
}
