import axios from 'axios'
import { requestUpdateMyUser } from '../constants/request'
import {
  fetchUserReuest,
  fetchUserSuccess,
  fetchUserFailure
} from '../actions/login'

const updateUser = (data) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserReuest())
      let res = await axios({
        method: 'patch',
        url: requestUpdateMyUser,
        data,
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      })
      const user = res.data
      dispatch(fetchUserSuccess(user))
    } catch (e) {
      dispatch(fetchUserFailure(e))
    }
  }
}

export default updateUser
