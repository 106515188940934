export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const FETCH_USERS_CLEAR = 'FETCH_USERS_CLEAR'

export const LOADING_TRUE = 'LOADING_TRUE'
export const LOADING_FALSE = 'LOADING_FALSE'

export const CONFIRM_CANCEL = 'CONFIRM_CANCEL'
export const CONFIRM_OK = 'CONFIRM_OK'

export const AUTH_FORM = 'AUTH_FORM'

export const AUTH_TAB = 'AUTH_TAB'

// TODO: tabs with one router

export const TAB_LENDING = 'TAB_LENDING'
export const TAB_LOGIN = 'TAB_LOGIN'
export const TAB_REGISTRATION = 'TAB_REGISTRATION'
export const TAB_RESET_PW = 'TAB_RESET_PW'

// TODO: steps of subtabs for tabs
export const SUB_TAB_REG_0 = 'SUB_TAB_REG_0'
export const SUB_TAB_REG_1 = 'SUB_TAB_REG_1'
export const SUB_TAB_REG_2 = 'SUB_TAB_REG_2'
export const SUB_TAB_REG_3 = 'SUB_TAB_REG_3'

export const SUB_TAB_RES_0 = 'SUB_TAB_RES_0'
export const SUB_TAB_RES_1 = 'SUB_TAB_RES_1'
export const SUB_TAB_RES_2 = 'SUB_TAB_RES_2'

// TODO: toasts
export const PUSH_NEW_TOAST = 'PUSH_NEW_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'
