import React, {useState, useRef} from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import * as Yup from 'yup'
import moment from 'moment';
import './index.css'
import Radio from '@material-ui/core/Radio';
import {login} from '../../../models';
import {
  requestCreateUser,
  requestUploadAvatar,
  requestGetMyAvatar,
  requestCheckExistUser
} from '../../../constants/request'
import {TextInput, DataPickerInput} from '../../../components'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import AvatarDefault from '../../../images/avatar.png'
import {
  useHistory
} from "react-router-dom"
import { loadingTrue, loadingFalse } from '../../../actions/loading'
import {
  SUB_TAB_REG_0,
  SUB_TAB_REG_1,
  SUB_TAB_REG_2,
  SUB_TAB_REG_3
} from '../../../constants/index'

import {
  setSubTabReg0,
  setSubTabReg1,
  setSubTabReg2,
  setSubTabReg3,
  setTabLending,
  setTabLogin
} from '../../../actions/setTab'

import cn from 'classnames';
import RegistrationCSS from'./Registration.module.css';

export default function RegistrationTab () {
  const refUpload = useRef()
  const {authTab} = useSelector(state => state)
  const regStep = authTab.regStep
  const dispatch = useDispatch()

  const [errorImg, setErrorImg] = useState('')
  const [errorReg, setErrorReg] = useState('')

  const [avatarUrl, setAvatarUrl] = useState(AvatarDefault)
  const [file, setFile] = useState({})

  const [userType, setUserType] = useState('VOLUNTEER')
  const history = useHistory()
  const [infoRegOrg, setInfoRegOrg] = useState(false)
  const [checkBoxes, setCheckBoxes] = useState({
    rules: false,
    agreement: false
  })

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    nickname: '',
    birthDate: '',
    phoneNumber: '',
    name: '',
    address: '',
    description: '',
    sex: 'FEMALE'
  })
  // const phoneRegexp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const pickImg = async (e) => {
    setErrorImg('')
    const f = e.target.files[0]

    if (f.size > 1000000) {
      return setErrorImg('розмір картинки завеликий')
    }

    const fd = new FormData()
    fd.append('file', f)

    setFile(fd)

    setAvatarUrl(URL.createObjectURL(e.target.files[0]))
  }

  const registration = async (email, password) => {
    setErrorReg('')

    let res = await axios.get(requestCheckExistUser + `?email=${email}`)

    if (res.data.data.exists) {
      return setErrorReg('Ця пошта вже зайнята')
    }

    dispatch(loadingTrue())

    if (userType === 'ORGANIZATION') {
      data.contactPerson = {
        name: data.name,
        phoneNumber: data.phoneNumber
      }
    }

    data.email = email
    data.password = password
    data.userType = userType

    if (userType === 'VOLUNTEER') {
      const dateUser = data.birthDate

      const year = moment(dateUser).format('yyyy');
      const month = moment(dateUser).format('MM');
      const newDay = moment(dateUser).format('DD');

      const readyDate = year + '-' + month + '-' + newDay;

      data.birthDate = readyDate
    }

    try {
      await axios.post(requestCreateUser, data)

      let newToken = await dispatch(login({email, password}))

      try {
        if (!!file) {
          await axios({
            method: 'post',
            url: requestUploadAvatar,
            data: file,
            headers: {
              'Authorization': newToken
            }
          })
        }
      } catch (e) {
        console.log('img error')
      }

      if (newToken) {
        if (userType === 'ORGANIZATION') {
          history.push('/profile')
        } else {
          history.push('/directions')
        }
      }
    } catch (e) {
      setErrorReg('Ця пошта вже зайнята')
    }

    dispatch(loadingFalse())
    dispatch(setTabLending())
  }

  const checkFirstStepAndNext = () => {
    // dispatch(setTab({len: 'log', log: 'reg', reg: 2}))
    dispatch(setSubTabReg2())
  }

  const initDataFirstStep = data

  let validationDate = {
    phoneNumber: Yup.string()
      // .matches(phoneRegexp, 'Номер телефону неправильний')
      .min(10, 'Повинен бути не менше 10 символів')
      .max(20, 'Повинен бути не більше 20 символів')
      .required('Напишіть номер телефону'),
    // description: Yup.string()
    //   .max(200, 'Повинен бути не більше 200 символів')
    //   .required('Розкажіть про себе'),
  }

  if (userType === 'VOLUNTEER') {
    validationDate.firstName = Yup.string()
      .max(15, 'Повинен бути не більше 30 символів')
      .required("Напишіть ім'я");
    validationDate.lastName = Yup.string()
      .max(20, 'Повинен бути не більше 30 символів')
      .required('Напишіть Прiзвище');
    validationDate.nickname = Yup.string()
      .max(20, 'Повинен бути не більше 20 символів')
      .required('Напишіть Нікнейм');
    validationDate.birthDate = Yup.string()
      .required('Напишіть дату народження');
  }

  if (userType === 'ORGANIZATION') {
    validationDate.name = Yup.string()
      .required("Напишіть ім'я");
    validationDate.address = Yup.string()
      .max(200, 'Повинен бути не більше 200 символів')
      .required("Напишіть адресу");
  }


  const validation = Yup.object(validationDate)

  const zeroStep = (
    <div style={{
      width: 400,
      height: 300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      
      <p className={ cn(RegistrationCSS.title,RegistrationCSS.tBlack,RegistrationCSS.firstStepTitle)} 
      style={{fontSize: 36}}>КИМ ТИ Є?</p>
      
      <div className={ cn(RegistrationCSS.blockButtons)} style={{
        height: 100,
        padding: 10
      }}>
        
        <div className={ cn(RegistrationCSS.button,RegistrationCSS.bgWhite,RegistrationCSS.firstStepOption)} onClick={() => {
          setUserType('VOLUNTEER')
          dispatch(setSubTabReg1())
        }}>          
          <p className={ cn(RegistrationCSS.title,RegistrationCSS.firstStepOptionText)}>ВОЛОНТЕР</p>
        </div>
        <div
          className={ cn(RegistrationCSS.button,RegistrationCSS.bgWhite,RegistrationCSS.firstStepOption)}
          onClick={() => {
            return setInfoRegOrg(true)
            dispatch(setSubTabReg1())
            setUserType('ORGANIZATION')
          }}
        >
          <p className={ cn(RegistrationCSS.title, RegistrationCSS.tBlack, RegistrationCSS.firstStepOptionText)}>ОРГАНІЗАЦІЯ</p>
        </div>
      </div>
    
      <div type='button' className={ cn(RegistrationCSS.zeroStepBackButton)} onClick={() => {
        dispatch(setTabLogin())
      }}>
        <ArrowBackRoundedIcon classes={{root: 'zero-step__back-button-icon'}} fontSize="inherit"/>
      </div>
    </div>
  )

  const firstStep = (
    <Formik
      initialValues={initDataFirstStep}
      onSubmit={(values, { setSubmitting }) => {
        setData(values);
        checkFirstStepAndNext();
      }}
      validationSchema={validation}
    >
      {({
        setFieldValue,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        isSubmitting,
        touched,
      }) => (
        <div
          className={ cn(RegistrationCSS.formInput)}
          style={{
            marginTop: 150,
            marginBottom: 100,
          }}
        >
          <p
            className={ cn(RegistrationCSS.title, RegistrationCSS.tWhite )}
            style={{ fontSize: 48, marginBottom: 30 }}
          >
            ДОДАЙ ІНФОРМАЦІЮ ПРО{" "}
            {userType === "VOLUNTEER" ? "СЕБЕ" : "ВАШУ ОРГАНІЗАЦІЮ"}
          </p>
          {userType === "VOLUNTEER" && (
            <>
              <TextInput
                value={values.firstName}
                onChange={handleChange("firstName")}
                error={touched.firstName && errors.firstName}
                showTextError={true}
                placeholder="Ім’я"
              />
              <br />
              <TextInput
                value={values.lastName}
                onChange={(e) => setFieldValue("lastName", e.target.value)}
                error={touched.lastName && errors.lastName}
                showTextError={true}
                placeholder="Прiзвище"
              />
              <br />
              <TextInput
                value={values.nickname}
                onChange={handleChange("nickname")}
                error={touched.nickname && errors.nickname}
                showTextError={true}
                placeholder="Нікнейм"
              />
            
              <div className= { cn(RegistrationCSS.sexQuestionWrapper)}>
                <p style={{ margin: "0 30px 0 0" }}>Стать</p>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={data.sex === "FEMALE"}
                    onChange={(e) => setData({ ...data, sex: e.target.value })}
                    value="FEMALE"
                    style={{ color: "#fff" }}
                  />
                  <p>Жіноча</p>
                </label>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                >
                  <Radio
                    style={{ color: "#fff" }}
                    checked={data.sex !== "FEMALE"}
                    onChange={(e) => setData({ ...data, sex: e.target.value })}
                    value="MALE"
                  />
                  <p>Чоловіча</p>
                </label>
              </div>
              <DataPickerInput
                value={values.birthDate}
                onChange={(data) => setFieldValue("birthDate", data)}
                error={touched.birthDate && errors.birthDate}
                showTextError={true}
                placeholder="Дата народження"
              />
              <br />
            </>
          )}
          {userType === "ORGANIZATION" && (
            <>
              <TextInput
                value={values.name}
                onChange={handleChange("name")}
                error={touched.name && errors.name}
                showTextError={true}
                placeholder="Назва організації"
              />
              <br />
              <TextInput
                value={values.address}
                onChange={handleChange("address")}
                error={touched.address && errors.address}
                showTextError={true}
                placeholder="Адреса"
              />
            </>
          )}
          <br />
          <TextInput
            type="tel"
            value={values.phoneNumber}
            onChange={handleChange("phoneNumber")}
            error={touched.phoneNumber && errors.phoneNumber}
            showTextError={true}
            placeholder="Номер телефону"
          />
          {/* <br/>
          <TextInput
            value={values.description}
            onChange={handleChange('description')}
            error={touched.description && errors.description}
            showTextError={true}
            placeholder='Про себе'
          /> */}
          <br />
  
          <div className={ cn(RegistrationCSS.avatarButtonWrapper)}>
            <button
              className= { cn(RegistrationCSS.avatarButtons, RegistrationCSS.avatarBackButton )} 
              onClick={() => dispatch(setSubTabReg0())}
            >
              <p>НАЗАД</p>
            </button>
            <button
              disabled={isSubmitting}
              className={ cn(RegistrationCSS.avatarButtons, RegistrationCSS.avatarNextButton )}
              onClick={handleSubmit}
            >
              <p>ДАЛІ</p>
            </button>
          </div>
        </div>
      )}
    </Formik>
  );

  let classNameImg = 'block-avatar'

  if (errorImg) {
    classNameImg = 'block-avatar-error'
  }

  const secondStep = (
    <div className={ cn(RegistrationCSS.formInput)}>
      
      <p className={ cn(RegistrationCSS.label, RegistrationCSS.white)} style={{fontSize: 48, marginBottom: 20}}>ЗАВАНТАЖ СВОЮ СВІТЛИНУ</p>
      <img className={classNameImg} src={avatarUrl} />
      {errorImg && (<p className={ cn(RegistrationCSS.tRed)}>{errorImg}</p>)}
      <input ref={refUpload} style={{display: 'none'}} type='file' onChange={pickImg} accept='image/*' />
      <p
        onClick={() => refUpload.current.click()}
        className={ cn(RegistrationCSS.white)}
        style={{fontSize: 15, fontFamily: 'Montserrat', marginTop: 10, cursor: 'pointer'}}
      >
        Завантажити свiтлину
      </p>
      
      <div className={ cn(RegistrationCSS.avatarButtonWrapper)}>
        <div className={ cn(RegistrationCSS.avatarButtons, RegistrationCSS.avatarBackButton)} onClick={() => dispatch(setSubTabReg1())}>
          <p>НАЗАД</p>
        </div>
        
        <div className={ cn(RegistrationCSS.avatarButtons, RegistrationCSS.avatarNextButton)} onClick={() => dispatch(setSubTabReg3())}>
          <p>ДАЛІ</p>
        </div>
      </div>
    </div>
  )

  const initDataThreeStep = {
    email: '',
    password: '',
    confirmPassword: ''
  }

  const validationThree = Yup.object({
    email: Yup.string()
      .email('Пошта неправильна')
      .required('Введіть пошту'),
    password: Yup.string()
      .min(9, 'Повинен бути не менше 9 символів')
      .max(20, 'Повинен бути не більше 20 символів')
      .required('Введіть пароль'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароль повинен збігатися')
      .required('Підтвердіть пароль')
  })


  const thirdStep = (
    <Formik
      initialValues={initDataThreeStep}
      onSubmit={(values, { setSubmitting }) => {
        registration(values.email, values.password);
        setSubmitting(false);
      }}
      validationSchema={validationThree}
    >
      {({
        setFieldValue,
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        isSubmitting,
        touched,
      }) => (
        <div className={ cn(RegistrationCSS.formInput)}>
          <p
          
            className={ cn(RegistrationCSS.auth, RegistrationCSS.label,  RegistrationCSS.white)}
            style={{ fontSize: 48, marginBottom: 20 }}
          >
            ДОСЛІДЖУЙ З НАМИ
          </p>
          <TextInput
            value={values.email}
            onChange={handleChange("email")}
            error={(touched.email && errors.email) || errorReg}
            showTextError={errorReg ? false : true}
            placeholder="Пошта"
          />
          <br />
          <TextInput
            value={values.password}
            onChange={handleChange("password")}
            error={(touched.password && errors.password) || errorReg}
            showTextError={errorReg ? false : true}
            type="password"
            placeholder="Пароль"
          />
          <br />
          <TextInput
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            error={
              (touched.confirmPassword && errors.confirmPassword) || errorReg
            }
            showTextError={errorReg ? false : true}
            type="password"
            placeholder="Повтор пароля"
          />
          <br />
         
          {errorReg && <p className= { cn(RegistrationCSS.Tred)}>{errorReg}</p>}
          <div style={{width: '65%', margin: '0 auto 10px'}}>
          <label style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
            <Checkbox
              style={{ color: "#272727" }}
              checked={checkBoxes.rules}
              onChange={(e) =>
                setCheckBoxes({
                  ...checkBoxes,
                  rules: e.target.checked,
                })
              }
            />
           
            <p className= { cn(RegistrationCSS.checkboxesText)}>Погоджуюся з <a style={{textDecoration: 'underline',
          cursor: 'pointer', color: "#272727"}} href='agreement.pdf' target="_blank">правилами користування</a></p>
          </label>
          <label style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
            <Checkbox
              style={{ color: "#272727" }}
              checked={checkBoxes.agreement}
              onChange={(e) =>
                setCheckBoxes({
                  ...checkBoxes,
                  agreement: e.target.checked,
                })
              }
            />
            <p style={{margin: 0, color: '#272727', fontFamily: 'Montserrat',
          fontSize: 15}}>Даю дозвіл на обробку персональних даних</p>
          </label>
          </div>
          <div style={{marginTop: 20, display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '80%'}}>
            <div
            
              className={ cn(RegistrationCSS.avatarButtons, RegistrationCSS.avatarBackButton)}
              style={{width: 230}}
              onClick={() => dispatch(setSubTabReg2())}
            >
              <p>НАЗАД</p>
            </div>
            <button
              disabled={!checkBoxes.rules ||
                !checkBoxes.agreement}
              style={{width: 230}}
              className={ cn(RegistrationCSS.avatarButtons, RegistrationCSS.avatarNextButton)}
              onClick={handleSubmit}
            >
              <p>ЗАРЕЄСТРУВАТИСЯ</p>
            </button>
          </div>
        </div>
      )}
    </Formik>
  );

  if (infoRegOrg) {
    return (
      <div style={{
        width: 400,
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
       
        <p className= {cn(RegistrationCSS.title, RegistrationCSS.tWhite )}>Щоб зареєструвати організацію на платформі, напишіть нам!</p>
        <p className= {cn(RegistrationCSS.tWhite )}>Пошта: info@volonteryou.com</p>
        <div className={cn(RegistrationCSS.button, RegistrationCSS.bgWhite )} onClick={() => {
          dispatch(setTabLending())
        }}>
          <p className={cn(RegistrationCSS.title )}>НА ГОЛОВНУ</p>
        </div>
      </div>
    )
  }

  switch (regStep) {
    case SUB_TAB_REG_0:
      return zeroStep
    case SUB_TAB_REG_1:
      return firstStep
    case SUB_TAB_REG_2:
      return secondStep
    case SUB_TAB_REG_3:
      return thirdStep
    default:
      return zeroStep
  }
}
