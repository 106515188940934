import React from "react"
import {
  BrowserRouter,
} from "react-router-dom";
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux';
import './App.css'
import thunk from 'redux-thunk'
import rootReducer from './reducers';

import Layout from './layout'

import 'semantic-ui-css/semantic.min.css'
import cacheImages from './utils/imageCaching';

// const ps = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
export const store = createStore(rootReducer, compose(
  applyMiddleware(thunk), 
  window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)

cacheImages();

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </Provider>
  )
}