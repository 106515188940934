import React, {useState, useRef, useEffect} from 'react'
import {
  Link
} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {getMyUser} from '../../../models';
import moment from "moment";
import { Task, TextInput, LevelLine} from '../../../components'
import DefoultAvatar from '../../../images/avatar.png'
import {FETCH_USERS_CLEAR} from '../../../constants/index'
import IconLocation from '../../../images/icon-location.svg';
import IconUser from '../../../images/icon-user.svg';
import IconMail from '../../../images/icon-message.svg';
import IconPhone from '../../../images/icon-phone.svg';
import { ModalTaskInfo } from './ModalTaskInfo'
import IconScore from "../../../images/score.svg";
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { confirmCancel, confirmOk } from '../../../actions/confirm';
import {pushToast} from '../../../actions/toasts';
import { useClearCache } from "react-clear-cache";
import {updateUser} from '../../../models';
import MainApi from '../../../utils/mainApi';
import style from './Volonter.module.css';
import './index.css';

import axios from 'axios'
import {
  requestCreateTask,
  requestGetTask,
  requestUploadAvatar,
  getVolonterTask,
  requestGetUserById,
  requestGetTaskDetails
} from '../../../constants/request'
import Compress from "react-image-file-resizer";

const TYPE_UPCOMING = 'upcoming';
const TYPE_COMPLETE = 'completed';
const initLimit = 10;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
}));

export default function Volonter({forceUpdate, user, seenByOrganization}) {
  const history = useHistory();
  const location = useLocation();
  const mainApi = MainApi.getInstance();
  const dispatch = useDispatch()
  const classes = useStyles();
  const {directions} = useSelector(state => state)
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState(user?.imgPath || DefoultAvatar)
  const [isEditDescriptionMode, setIsEditDescriptionMode] = useState(false)
  const fullName = user?.firstName + ' ' + user?.lastName
  const refUpload = useRef()
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const [name, setName] = useState('')
  const [description, setDescription] = useState(user?.description)
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [type, setType] = useState('')
  const [error, setError] = useState('')
  const [file, setFile] = useState({})
  const [tasks, setTasks] = useState([])
  const [missionTab, setMissionTab] = useState(TYPE_UPCOMING);
  const [limit, setLimit] = useState(initLimit);
  const age = moment.duration(moment().diff(user.birthDate))

  const linkRef = useRef()

  const [visibleModalInfo, setVisibleModalInfo] = useState(false);
  const [currentTask, setCurrentTask] = useState()

  const logout = () => {
    const isLogout = window.confirm('Ви нас залишаєте?');
    if (isLogout) {
      dispatch({type: FETCH_USERS_CLEAR})
      localStorage.clear()
    }
  }

  const updateDesription = () => {
    const changedData = {...user}
    changedData.description = descriptionValue
    dispatch(updateUser(changedData));
    setDescription(descriptionValue)
    setIsEditDescriptionMode(false);
  }

  const updateTasks = async () => {
    const res = await mainApi.getMissionForUser({
      status: missionTab,
      limit
    });
    setTasks(res.data)
  }

  const pickImg = async (e) => {
    setError('')
    const f = e.target.files[0]
    const fd = new FormData()

    Compress.imageFileResizer(
      f,
      400,
      430,
      "WEBP",
      100,
      0,
      (resizedFile) => {

        fd.append('file', resizedFile)

        uploadImg(fd).then(() => {

          setAvatar(URL.createObjectURL(resizedFile))

          dispatch(pushToast({
            status: 'success',
            text: 'Фото завантажено'
          }))
        }).catch(() => {

          dispatch(pushToast({
            status: 'error',
            text: 'Помилка завантаження зображення'
          }))
        })
      },
      'blob'
    );

    // if (f.size > 1000000) {
    //   return setError('розмір картинки завеликий')
    // }
  }

  const uploadImg = async (fd) => {
    try {
      await axios({
        method: 'post',
        url: requestUploadAvatar,
        data: fd,
        headers: {
          'Authorization': localStorage.getItem('token'),
        }
      })
    } catch (e) {

      throw e;
    }
  }

  useEffect(() => {
    updateTasks()
  }, [missionTab, limit])

  const clickTask = async (data) => {


    setCurrentTask(data)
    setVisibleModalInfo(true)
  }

  const rejectMission = (missionId) => {
    dispatch(
      confirmOk({
        title: "ВИ ВПЕВНЕНІ, ЩО ХОЧЕТЕ ВІДМОВИТИСЯ ВІД МІСІЇ?",
        open: true,
        errorAlert: false,
        onClose: () => {
          dispatch(confirmCancel());
        },
        onOk: () => {
          mainApi.rejectMission(missionId).then(res => {
            dispatch(pushToast({
              status: 'success',
              text: 'Ви відмовилися від місії'
            }))
            setTasks(tasks.filter(item => item.id !== missionId))
            dispatch(confirmCancel());
          }).catch(err => err)
        },
      })
    );
  }

  const tasksCompleted = tasks.filter(task => task.completed === true)
  const tasksNotCompleted = tasks.filter(task => task.completed === false)
  return (
    <div className="cover p-profile-volunteer">
      <Link to="/profile-change" ref={linkRef} style={{ display: "none" }} />
     {
       seenByOrganization
       ?  <div style={{margin: `10px 0 50px 150px`, display: 'flex', alignItems: 'center', alignSelf: 'flex-start'}}
       onClick={() => history.push({
         pathname: '/profile',
         state: {
           mission: location.state.mission,
           isComeFromVolonter: true
         }
       })}>
         <div type='button' className='back-button__volonter'>
         <ArrowBackRoundedIcon classes={{root: 'zero-step__back-button-icon'}} fontSize="inherit"/>
       </div>
       <span style={{marginLeft: 10, fontFamily: 'Montserrat', fontSize: 15}}>Повернутися до місії</span>
         </div>
         : null
     }
      <div className="p-profile-content">
        <div className="p-profile-content-left">
          <div className="p-profile-div-avatar">
            <div
              style={{
                position: "relative",
              }}
            >
              <img className="p-profile-img-avatar" src={avatar} />
              {
                !seenByOrganization
                ? <>
                <IconButton
                color="primary"
                component="span"
                classes={{ root: "avatar-photo__wrapper" }}
                onClick={() => refUpload.current.click()}
              >
                <PhotoCameraIcon style={{ color: "#fff", fontSize: 26 }} />
              </IconButton>
              <div className="bg-level-block-vol">
                <p className="title t-white">{user?.levelInfo?.level}</p>
              </div>
                </>
                : null
              }
            </div>
            {error && <p className="text red">{error}</p>}
            <input
              ref={refUpload}
              style={{ display: "none" }}
              type="file"
              onChange={pickImg}
              accept="image/*"
            />
            <br />
           {
             !seenByOrganization
             ? <>
              <div
              className="div-button-edit-profile"
              onClick={() => linkRef.current.click()}
            >
              <p className="text white">РЕДАГУВАТИ ПРОФiЛЬ</p>
            </div>
            <br />
            <div className="div-button-logaut" onClick={logout}>
              <p className="text">вийти з профiлю</p>
            </div>
             </>
             : null
           }
          </div>
          <div className="p-profile-div-info-user" style={{padding: seenByOrganization ? '0 10px' : '30px 10px'}}>
            <span className="title volonter-name__title" style={{margin: '20px 0 5px'}}>
              {fullName || "ІМ’Я ВОЛОНТЕРА"}
            </span>
            <p className="text volonter-nickname" style={{marginTop: 10}}>
              {`@${user?.nickname}` || "@nickname"}
            </p>
            {
              seenByOrganization
              ? <div style={{width: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                   <img src={IconUser} style={{width: 35, height: 35}}/>
                   <p style={{marginLeft: 10, fontFamily: 'Montserrat', fontSize: 15}}>{`${age.years()} років`}</p>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 3}}>
                <img src={IconPhone} style={{width: 35, height: 35}}/>
                   <p style={{marginLeft: 10, fontFamily: 'Montserrat', fontSize: 15}}>{user.phoneNumber || '-'}</p>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 3}}>
                <img src={IconMail} style={{width: 35, height: 35}}/>
                   <p style={{marginLeft: 10, fontFamily: 'Montserrat', fontSize: 15}}>{user.email || '-'}</p>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 3}}>
                <img src={IconLocation} style={{width: 35, height: 35}}/>
                   <p style={{marginLeft: 10, fontFamily: 'Montserrat', fontSize: 15}}>{user.address || '-'}</p>
                </div>
              </div>
              : <div className="p-profile-div-line-level">
              <LevelLine levelInfo={user?.levelInfo} />
              <div className="p-profile-div-line-level-data">
                <p style={{ marginBottom: 0 }}>
                  {user?.levelInfo?.currentLevelPoints +
                    "/" +
                    user?.levelInfo?.nextLevelPoints}
                </p>
                <img
                  src={IconScore}
                  style={{
                    width: 30,
                    height: 30,
                    marginLeft: 5,
                  }}
                />
              </div>
            </div>
            }
          </div>
        </div>

        <div className="p-profile-content-right" style={{marginLeft: 20}}>
          <div className="p-profile-div-description">
            <p style={{fontFamily: 'Montserrat', fontSize: 15, fontWeight: 'bold', marginBottom: 10, marginLeft: 10 }}
            >Про волонтера:</p>
            {
              isEditDescriptionMode
              ? <TextField multiline rowsMax={10} rows={10} value={descriptionValue} onChange={(e) => setDescriptionValue(e.target.value)} InputProps={{classes}} autoFocus style={{fontSize: 14, padding: '0 10px', borderRadius: 5,
            border: isEditDescriptionMode ? '1px solid #ebf9f6' : 'none'}}/>
              : <p className="text" style={{textTransform: 'none', padding: '0 10px', hyphens: 'auto', maxHeight: 200,
            overflow: 'auto'}}>{description}</p>
            }
            {
              isEditDescriptionMode
              ? <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 10, marginLeft: 10}}>
                <p className="edit-text__button" style={{marginRight: 20}}
                onClick={() => updateDesription()}
                >Зберегти</p>
                <p className="edit-text__button"
                onClick={() => {
                  setIsEditDescriptionMode(false);
                  setDescriptionValue(description)
                }}>Скасувати</p>
                </div>
              :  !isEditDescriptionMode && !seenByOrganization
              ? <p className="edit-text__button" style={{marginLeft: 10}}
              onClick={() => setIsEditDescriptionMode(true)}
              >
                Редагувати
              </p>
              : null
            }
          </div>
        </div>
      </div>

      {
        seenByOrganization
        ? null
        : <div className={style.blockMissions}>
        <p className={style.title}>МОЇ МІСІЇ</p>
        <div className={style.blockMissionButtons}>
          <div className={missionTab === TYPE_UPCOMING ? style.missionTabActive : style.missionTab} onClick={() => setMissionTab(TYPE_UPCOMING)}>
            <p className={missionTab === TYPE_UPCOMING ? style.missionTextActive : style.missionText}>Активні</p>
          </div>
          <div className={missionTab === TYPE_COMPLETE ? style.missionTabActive : style.missionTab}
          style={{
            backgroundColor: missionTab !== TYPE_UPCOMING && '#35B899'
          }}
          onClick={() => setMissionTab(TYPE_COMPLETE)}>
            <p className={missionTab === TYPE_COMPLETE ? style.missionTextActive : style.missionText}>Завершені</p>
          </div>
        </div>
        {tasks.length > 0 && (
          <div className={style.taskList}>
            {tasks.map(mission => {
              return (
                <Task
                  key={mission.id}
                  mission={mission}
                  deleteTask={() => rejectMission(mission.id)}
                  onClick={() => clickTask(mission)}
                />)
            })}
          </div>
        )}
        {limit === tasks.length && (
          <div className={style.missionTabActive } onClick={() => setLimit(limit + initLimit)}>
            <p className={style.missionTextActive}>Шукати ще місії +</p>
          </div>
        )}
      </div>
      }

      {currentTask && (
        <ModalTaskInfo
          open={visibleModalInfo}
          handleClose={() => setVisibleModalInfo(false)}
          currentTask={currentTask}
        />
      )}
    </div>
  );
}
